import { ChatType, SalesForceChatHook } from 'components/organisms/list/types';
import { gtmTriggerEvent } from 'lib/gtm';
import { useEffect, useRef, useState } from 'react';
import addEventHandlers from 'util/salesforceChatEvents';
import overrideSettings from 'util/salesforceChatSettings';
import { getChatOptions } from './getChatOptions';

declare global {
  interface Window {
    embedded_svc: any;
  }
}

const SF_SERVICE_URL = 'https://service.force.com';
const EXISTING_SCRIPT_MESSAGE =
  'loadEmbeddedService was called but an existing Embedded Service script already exists in the document; existing script parameters will be used';
const V5_URL = 'https://service.force.com/embeddedservice/5.0/esw.min.js';

const useSalesForceChat = (): SalesForceChatHook => {
  const [embeddedSvc, setEmbeddedSvc] = useState<any>(null);
  const [agentAvailable, setAgentAvailable] = useState(false);
  const [chatLoaded, setChatLoaded] = useState(false);
  const [chatLoadedWithError, setChatLoadedWithError] = useState<
    boolean | undefined
  >();
  const [chatInited, setChatInited] = useState(false);
  const mustRunCode = useRef(true);

  useEffect(() => {
    if (!window.embedded_svc) {
      const script = document.createElement('script');

      script.src = V5_URL;
      script.onload = () => {
        setChatLoaded(true);
        setEmbeddedSvc(window.embedded_svc);
      };
      script.onerror = () => {
        setChatLoadedWithError(true);
      };

      document.body.appendChild(script);
    } else {
      console.warn(EXISTING_SCRIPT_MESSAGE);
    }
  }, []);

  useEffect(() => {
    if (chatLoadedWithError) {
      setChatLoaded(true);
      gtmTriggerEvent('error_contact_options_load_chat');
    }
  }, [chatLoadedWithError]);

  useEffect(() => {
    if (!embeddedSvc) return;
    if (mustRunCode.current) {
      mustRunCode.current = false;
      addEventHandlers(embeddedSvc, setAgentAvailable, setChatInited);
    }
  }, [embeddedSvc]);

  const initChat = (type: ChatType) => {
    const chatOptions = getChatOptions(type);

    overrideSettings(
      embeddedSvc,
      [
        'displayHelpButton',
        'defaultMinimizedText',
        'disabledMinimizedText',
        'loadingText',
        'enabledFeatures',
        'entryFeature',
        'language',
        'prepopulatedPrechatFields',
      ],
      chatOptions.chatOptions,
    );

    embeddedSvc.init(
      chatOptions.salesForceUrl,
      chatOptions.siteUrl,
      SF_SERVICE_URL,
      chatOptions.chatId,
      chatOptions.chatName,
      chatOptions.chatOptions,
    );
  };

  return {
    embeddedSvc,
    agentAvailable,
    chatLoaded,
    chatLoadedWithError,
    initChat,
    chatInited,
  };
};

export default useSalesForceChat;
