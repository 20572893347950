import { createContext, useEffect } from 'react';

import useSalesForceChat from 'lib/chat-service/salesForceChat';
import useLoggedUser from 'hooks/useLoggedUser';
import useUserIsPro from 'hooks/useUserIsPro';

export const ChatContext = createContext(null);

type Props = {
  children: any;
};

export const ChatProvider = ({ children }: Props) => {
  const {
    agentAvailable,
    chatLoaded,
    chatLoadedWithError,
    embeddedSvc,
    initChat,
    chatInited,
  } = useSalesForceChat();
  const { user } = useLoggedUser();
  const userIsPro = useUserIsPro(user);

  useEffect(() => {
    if (!userIsPro) return;
    const { isProImmobile, isProAutos } = userIsPro;
    window.sessionStorage.setItem('isProAutos', isProAutos ? 'true' : '');
    window.sessionStorage.setItem('isProImmobile', isProImmobile ? 'true' : '');
  }, [userIsPro]);

  return (
    <ChatContext.Provider
      /* @ts-ignore */
      value={{
        embeddedSvc,
        chatLoadedWithError,
        agentAvailable,
        chatLoaded,
        initChat,
        chatInited,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
