import { css } from 'styled-components';

export const fontSemiSmall = css`
  line-height: ${14 * 1.5}px;
  font-size: 14px;
`;

export const fontSmall = css`
  line-height: ${12 * 1.333}px;
  font-size: 12px;
`;
