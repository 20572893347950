import styled from 'styled-components';

const TagPMarkdown = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0px;
  color: #4a4a4a;
  margin: 24px 0;
`;

export default TagPMarkdown;
