import { Inbox, ChevronRight } from '@olxbr/ds-icons';
import { Text, Flex } from '@olxbr/ds-web';
import { useRouter } from 'next/router';
import { FC, MouseEventHandler } from 'react';
import { useLoggedUser } from 'hooks';
import { Wrapper } from './styles';

type MyTicketsHeaderProps = {
  // eslint-disable-next-line no-unused-vars
  onCallButtonClick?: (e: any) => void | null;
  isLateralMenu?: boolean;
};
const MyTicketsHeader: FC<MyTicketsHeaderProps> = ({
  isLateralMenu,
  onCallButtonClick = () => null,
}) => {
  const { user, loading } = useLoggedUser();
  const router = useRouter();

  const onDirectMyTickets: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (isLateralMenu) {
      onCallButtonClick(e);
    }

    if (router.asPath !== '/meus-atendimentos') {
      router.push('/meus-atendimentos');
    }
  };

  if (!user && !loading) {
    return null;
  }

  return (
    <Wrapper onClick={onDirectMyTickets}>
      <Flex alignItems="center">
        <Inbox size="36" color="var(--color-neutral-130)" />
        <Text.BodyMedium color="--color-neutral-130" marginLeft="--spacing-1">
          Meus atendimentos
        </Text.BodyMedium>
      </Flex>
      {isLateralMenu && <ChevronRight size="24" color="--color-neutral-130" />}
    </Wrapper>
  );
};

export default MyTicketsHeader;
