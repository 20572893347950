import styled from 'styled-components';
import { clickLink } from 'tracking/lurker';
import isWithModifier from 'util/isWithModifier';
import redirect from 'util/redirect';
import { SimpleLink, TitleMedium } from 'components/atoms';
import { v4 as uuidv4 } from 'uuid';

type PropLink = {
  name: string;
  url: string;
};

type Props = {
  title: string;
  links: PropLink[];
  eventName?: string | null;
  pageName?: string;
};

const WrapperComponent = styled.div`
  margin: 48px 0;
  margin-top: 24px;

  @media only screen and (min-width: 768px) {
    margin: 24px 0;
  }
`;

const WrapperLink = styled.div`
  margin: 1rem 0;

  @media (min-width: 1024px) {
    margin: 0.5rem 0;
  }

  @media (min-width: 1440px) {
    margin: 1rem 0;
  }
`;

const TitleWithLinks = ({
  title,
  links,
  eventName,
  pageName = 'article',
}: Props) => {
  const handleClick = (ev: any) => {
    if (!eventName) return;
    const href = ev.target.href || ev.currentTarget.href;

    clickLink(eventName, `central_${pageName}`, {
      to: href,
    });

    if (!isWithModifier(ev)) {
      ev.preventDefault();

      setTimeout(() => {
        redirect(href);
      }, 300);
    }
  };

  return (
    <WrapperComponent>
      <TitleMedium>{title}</TitleMedium>
      <div>
        {links.map((link) => (
          <WrapperLink key={`${uuidv4()}-${link.url.trim()}`}>
            <SimpleLink onClick={handleClick} href={link.url.trim()}>
              {link.name}
            </SimpleLink>
          </WrapperLink>
        ))}
      </div>
    </WrapperComponent>
  );
};

TitleWithLinks.defaultProps = {
  eventName: null,
  pageName: 'article',
};

export default TitleWithLinks;
