import { createContext, ReactNode, useState } from 'react';

type BannerType = {
  content: string;
  title?: string;
  incident: boolean;
};

type ContextType = {
  banners: BannerType[];
  setBanners: Function;
};

export const BannerContext = createContext<ContextType>({
  banners: [],
  setBanners: (banners: BannerType[]) => banners,
});

type Props = {
  children: ReactNode;
};

const BannerProvider = (props: Props) => {
  const { children } = props;

  const [banners, setBanners] = useState([]);

  return (
    <BannerContext.Provider value={{ banners, setBanners }}>
      {children}
    </BannerContext.Provider>
  );
};

export default BannerProvider;
