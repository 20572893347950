import styled from 'styled-components';
import PropTypes from 'prop-types';
import View from '../View';

const Content = styled(View)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Line = styled(View)`
  width: 100%;
  height: 2;
  position: absolute;
  top: 50%;
  border-style: solid;
  border-width: 0;
  border-top-width: 1;
  border-color: #f2f2f2;
`;

const Title = styled(View)`
  font-size: 14;
  color: #898989;
  background-color: #ffffff;
  padding-top: 8;
  padding-right: 8;
  padding-bottom: 8;
  padding-left: 8;
`;

const ruleWithText = (props) => (
  <Content {...props}>
    <Line />
    {props.text && <Title>{props.text}</Title>}
  </Content>
);

ruleWithText.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
};

ruleWithText.defaultProps = {
  color: '#f2f2f2',
};

export default ruleWithText;
