import PropTypes from 'prop-types';
import { Box, Svg } from './styles';

const SocialMediaLink = ({ bgColor, fill, link, path }) => (
  <Box bgColor={bgColor} fill={fill} href={link}>
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <path d={path} className="socialIcon" />
    </Svg>
  </Box>
);

SocialMediaLink.propTypes = {
  path: PropTypes.string,
  bgColor: PropTypes.string,
  fill: PropTypes.string,
  link: PropTypes.string,
};

export default SocialMediaLink;
