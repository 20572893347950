import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { User } from 'typings/user';
import { accountsURL, fetcher } from './fetcher';

type TypeHook = {
  user: any;
  loading: boolean;
  error?: any;
};
const buildUser = (data: User): User => ({
  ...data,
  firstName: data.nickname || data.fullName?.split(' ')[0],
});

const useLoggedUser = (): TypeHook => {
  const [user, setUser] = useState<any | null>(undefined);

  const { data, error } = useSWR(accountsURL, fetcher, {
    revalidateOnFocus: false,
  });

  const updateUserData = async (dataUser: User) => {
    setUser(buildUser(dataUser));
  };

  useEffect(() => {
    if (data !== undefined && (!data || error)) {
      setUser(null);
      return;
    }

    if (data) {
      updateUserData(data);
    }
  }, [data]);

  return {
    user,
    loading: false,
    error,
  };
};

export default useLoggedUser;
