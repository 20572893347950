/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';
import SlickSlider from 'react-slick';

const SliderStyled = styled(SlickSlider)`
  overflow: hidden;

  .slick-list {
    width: 100%;

    @media (min-width: 768px) {
      background: #d2d2d2;
      padding: calc(40px - 12px) 50px;
    }
  }

  .slick-slide {
    > div {
      border-radius: 8px;
      box-shadow: 0px 0 8px rgba(0, 0, 0, 0.16);
      margin: 12px 24px;
      overflow: hidden;
    }
  }

  .slick-dots {
    bottom: 0;
    margin: 18px 0;
    position: relative;

    li {
      margin: 0;

      button {
        &:before {
          color: #d2d2d2;
          font-size: 8px;
          opacity: 1;
          transition: 0.2s color;
        }
      }

      &.slick-active button:before {
        color: #6e0ad6;
      }
    }
  }
`;

const SlideImageText = styled.footer`
  background: #e5e5e5;
  border-radius: 0 0 8px 8px;
  color: #4a4a4a;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: 12px 16px;
`;

const ImageItem = styled.img`
  width: '190px';
  height: '340px';
`;

type Props = {
  images: {
    alt: string;
    src: string;
  }[];
};

const Slider = ({ images }: Props) => {
  const settings = {
    arrows: false,
    centerMode: true,
    dots: images.length > 2,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    showArrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: images.length > 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: images.length > 1,
        },
      },
    ],
  };

  return (
    <SliderStyled {...settings}>
      {images.map(({ src, alt }) => (
        <div key={src} className="slick-image-item">
          <ImageItem src={src} alt={alt} />
          <SlideImageText>{alt}</SlideImageText>
        </div>
      ))}
    </SliderStyled>
  );
};

export default Slider;
