import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { GET_FAQ_ARTICLES } from 'graphql/queries';
import { FAQItem, FAQType } from 'graphql/types/faq';
import { ShowDesktop, ShowMobile, TitleMedium } from 'components/atoms';
import { TitleWithLinks } from 'components/molecules';
import { FAQSlider } from 'components/organisms';
import RecordFlattener from 'util/cmsRecordFlattener';

const FAQContainer = styled.div`
  margin: 2rem 0;

  @media (min-width: 1024px) {
    margin: 1rem 0;
  }

  @media (min-width: 1440px) {
    margin: 2rem 0;
  }
`;

const convertToLink = ({ article }: FAQItem) => ({
  name: article.name,
  url: `/s/article/${article.slug}`,
});

const convertToSlider = ({ article }: FAQItem) => ({
  name: article.name,
  id: article.id,
  slug: article.slug,
});

const FAQ = () => {
  const { data } = useQuery<FAQType>(GET_FAQ_ARTICLES);
  const flattener = new RecordFlattener();
  const faq = flattener.flatten(data);

  if (!faq) return null;

  const title = 'Perguntas frequentes';

  return (
    <FAQContainer>
      <ShowMobile>
        <TitleMedium>{title}</TitleMedium>
        <FAQSlider articles={faq.faqs.map(convertToSlider)} />
      </ShowMobile>

      <ShowDesktop>
        <TitleWithLinks
          title={title}
          eventName="faq"
          pageName="home"
          links={faq.faqs.map(convertToLink)}
        />
      </ShowDesktop>
    </FAQContainer>
  );
};

export default FAQ;
