export default class RecordFlattener {
  flattenArray(obj: any) {
    return obj.map((e: any) => this.flatten(e));
  }

  flattenData(obj: any) {
    if (!obj) {
      return null;
    }
    return this.flatten(obj.data);
  }

  flattenAttrs(obj: any) {
    const attrs = {} as any;

    Object.keys(obj.attributes).forEach((key) => {
      attrs[key] = this.flatten(obj.attributes[key]);
    });

    return {
      id: obj.id,
      ...attrs,
    };
  }

  public flatten(record: any): any {
    if (Array.isArray(record)) {
      const res = this.flattenArray(record);
      return res;
    }

    if (record && record.data) {
      const res = this.flattenData(record);
      return res;
    }

    if (record && record.attributes) {
      const res = this.flattenAttrs(record);
      return res;
    }

    if (record && typeof record === 'object') {
      const mutatedObj = { ...record };
      Object.keys(record).forEach((key) => {
        const res = this.flatten(record[key]);
        mutatedObj[key] = res;
      });
      return mutatedObj;
    }

    return record;
  }
}
