import styled from 'styled-components';

const Container = styled.div`
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 24px;
  }

  @media (min-width: 1440px) {
    margin: 80px auto;
    max-width: 1224px;
  }
`;

export default Container;
