import validIsCampana from 'api/get-is-campana';

const NAME_STORAGE = 'is_campana';
const NAME_STORAGE_EXPIRE = 'expire_campana';
const TIME_EXPIRE = 1000 * 60 * 60 * 8; // 8 hours
const IS_CAMPANA_ENABLED = !!process.env.NEXT_PUBLIC_IS_CAMPANA_ENABLED;

export const validateIsCampana = async (email: string): Promise<boolean> => {
  if (!IS_CAMPANA_ENABLED) {
    return false;
  }

  const storedEmail = localStorage.getItem(NAME_STORAGE);
  const expirationTime = Number(localStorage.getItem(NAME_STORAGE_EXPIRE)) || 0;
  const currentTime = Date.now();

  if (storedEmail === email) {
    return true;
  }

  if (expirationTime && currentTime < expirationTime) {
    return false;
  }

  const isCampana = await validIsCampana(email);

  if (isCampana) {
    localStorage.setItem(NAME_STORAGE, email);
    localStorage.removeItem(NAME_STORAGE_EXPIRE);
  } else {
    const newExpirationTime = currentTime + TIME_EXPIRE;
    localStorage.setItem(NAME_STORAGE_EXPIRE, String(newExpirationTime));
  }

  return isCampana;
};

export const getIsCampana = (email?: string): boolean => {
  if (!IS_CAMPANA_ENABLED) {
    return false;
  }

  if (!email) return false;

  const isCampana = localStorage.getItem(NAME_STORAGE);

  if (isCampana === email) {
    return true;
  }

  return false;
};
