import gql from 'graphql-tag';

export const GET_CATEGORY_LIST = gql`
  query {
    categories(
      sort: "position"
      filters: { parent: { id: { null: true } } }
      pagination: { pageSize: 500 }
    ) {
      data {
        id
        attributes {
          title
          slug
        }
      }
      meta {
        pagination {
          pageSize
          page
          pageCount
          total
        }
      }
    }
  }
`;

export const GET_CATEGORY_BY_SLUG = gql`
  query getSubCategories($slug: String) {
    categories(
      sort: "position"
      filters: { slug: { eq: $slug } }
      pagination: { start: 0, limit: 1 }
    ) {
      data {
        id
        attributes {
          title
          slug
          position
          categories(sort: "position") {
            data {
              id
              attributes {
                title
                position
                slug
              }
            }
          }
        }
      }
      meta {
        pagination {
          pageSize
          page
          pageCount
          total
        }
      }
    }
  }
`;

export const GET_SUBCATEGORY_BY_SLUG = gql`
  query getSubCategories($slug: String) {
    categories(
      sort: "position"
      filters: { slug: { eq: $slug } }
      pagination: { start: 0, limit: 1 }
    ) {
      data {
        id
        attributes {
          title
          slug
          position
          parent {
            data {
              id
              attributes {
                title
                slug
              }
            }
          }
        }
      }
      meta {
        pagination {
          pageSize
          page
          pageCount
          total
        }
      }
    }
  }
`;
