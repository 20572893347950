import { Category } from 'graphql/types';
import styled from 'styled-components';
import { Container, ButtonAcessibility } from 'components/atoms';
import Head from 'next/head';
import { ContactUs, CategoriesList } from 'components/organisms';
import { pageView } from 'tracking/lurker';
import { FAQ, SeoFaq, SearchBar } from 'components/templates';
import BridgeJS from 'util/bridgeWebView';
import { useEffect } from 'react';
import { User } from 'typings/user';

const WrapContainer = styled(Container)`
  margin-top: 32px;
`;

const SearchContainer = styled.div`
  @media only screen and (min-width: 1024px) {
    display: flex;
  }
`;
const ImageContainer = styled.div`
  display: none;
  @media only screen and (min-width: 1024px) {
    display: block;
    min-width: 40%;
    padding-right: 64px;
  }

  @media (min-width: 1440px) {
    padding-right: 128px;
  }

  > img {
    max-width: 500px;
    max-height: 388px;

    @media (min-width: 1440px) {
      max-width: 600px;
    }
  }
`;

const RightColumn = styled.div`
  flex: 1;
  margin: auto;

  @media (min-width: 1024px) {
    max-width: 496px;
  }

  @media only screen and (min-width: 1024px) {
    margin-left: auto;
  }
`;

const SearchTitle = styled.div`
  max-width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #4a4a4a;
  margin-bottom: 24px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 16px;
    font-size: 30px;
    line-height: 30px;
  }

  @media (min-width: 1440px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

const CategoriesContainer = styled.div`
  margin-top: 12px;

  @media only screen and (min-width: 1024px) {
    max-width: 1248px;
    margin: auto;
    margin-top: 95px;
  }
`;

const TitleMedium = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #4a4a4a;
  @media only screen and (min-width: 1024px) {
    margin-left: 12px;
    font-size: 24px;
    line-height: 32px;
  }
`;

const Subtitle = styled.div`
  color: #4a4a4a;
  display: flex;
  align-items: center;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 24px;
  padding: 8px 0;

  @media only screen and (min-width: 1024px) {
    margin-left: 12px;
  }
`;

const ImageBottom = styled.div`
  margin-top: 96px;
  margin-left: -24px;
  img {
    width: 100%;
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;

const Spacement = styled.div`
  height: 178px;
`;
type Props = {
  categories: [Category];
  faqs: [any];
  bridge: BridgeJS;
  user: null | User;
};
const CentralHome = (props: Props) => {
  const { categories, faqs, bridge, user } = props;

  useEffect(() => {
    if (user) {
      pageView('central_home', 'entrou_logado');
    }
  }, [user]);

  useEffect(() => {
    pageView('central_home');
  }, []);
  return (
    <>
      <Head>
        <title>OLX - Central de ajuda</title>
      </Head>
      <SeoFaq faqs={faqs} />
      <WrapContainer>
        <SearchContainer>
          <ImageContainer>
            <img
              src="/help_center_images/home-boy.svg"
              loading="lazy"
              alt="Central de ajuda"
            />
          </ImageContainer>
          <RightColumn>
            <SearchTitle>Como podemos te ajudar? :)</SearchTitle>
            <SearchBar />
            <FAQ />
          </RightColumn>
        </SearchContainer>
        <CategoriesContainer>
          <TitleMedium>Não quer pesquisar?</TitleMedium>
          <Subtitle>Procure aqui sua dúvida ou problema</Subtitle>
          <CategoriesList categories={categories} />
        </CategoriesContainer>

        <ImageBottom>
          <img
            src="/help_center_images/home-boy-mobile.svg"
            alt="Central de ajuda"
          />
        </ImageBottom>

        <ContactUs />

        {bridge.isIosWebView() && <Spacement />}
        <ButtonAcessibility />
      </WrapContainer>
    </>
  );
};

export default CentralHome;
