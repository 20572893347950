/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';

const WrapperLink = styled.a`
  align-items: center;
  color: #4a4a4a;
  cursor: pointer;
  display: flex;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  justify-content: space-between;
  margin: 1rem 0;
  text-decoration: none;
  :hover {
    color: #6e0ad6;
  }
`;

const WrapArrow = styled.div`
  display: flex;
  @media (max-width: 600px) {
    margin-right: -7px;
  }
`;

type Props = {
  title: string;
  url: string;
  linkProps?: {
    [key: string]: any;
  };
};

const ThemeLink = ({ title, url, linkProps }: Props) => (
  <WrapperLink href={url} {...linkProps}>
    <div>{title}</div>
    <WrapArrow>
      <img src="/help_center_images/right-arrow.svg" alt="seta" />
    </WrapArrow>
  </WrapperLink>
);

ThemeLink.defaultProps = {
  linkProps: {},
};

export default ThemeLink;
