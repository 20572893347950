import { ReactNode } from 'react';
import styled from 'styled-components';

import BridgeJS from 'util/bridgeWebView';
import Header from 'components/atoms/header';
import Footer from 'components/templates/footer';
import Banner from 'components/templates/banner';
import Background from 'components/templates/background';
import { BannerProvider } from 'components/context';
import IncidentBanner from 'components/templates/incident-banner';
import { useRouter } from 'next/router';

type Props = {
  children?: ReactNode;
  error?: boolean;
};

const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  position: relative;
`;

const Layout = ({ children, error }: Props) => {
  const { pathname = '' } = useRouter();
  const bridge = BridgeJS.getInstance();
  const isWebView = bridge.isWebView();

  const isCentralHome = pathname.includes('/s');

  return (
    <BannerProvider>
      <Wrapper>
        <Banner />
        <IncidentBanner />

        {!isWebView && <Header />}
        {isCentralHome && <Background />}
        {children}
        {!error && !isWebView ? <Footer /> : ''}
      </Wrapper>
    </BannerProvider>
  );
};

Layout.defaultProps = {
  children: null,
  error: false,
};

export default Layout;
