import { IconButton } from 'components/atoms';

type Props = {
  isUnlike?: boolean;
  onClick: Function;
};

const LikeButton = ({ isUnlike, onClick }: Props) => {
  const icon = isUnlike
    ? '/help_center_images/thumbs-down.svg'
    : '/help_center_images/thumbs-up.svg';

  const body = isUnlike ? 'Não' : 'Sim';

  return (
    <IconButton icon={icon} onClick={onClick}>
      {body}
    </IconButton>
  );
};

LikeButton.defaultProps = {
  isUnlike: false,
};

export default LikeButton;
