import styled from 'styled-components';
import medias from './medias.js';
import { fontSmall, fontSemiSmall } from './font';

export const Wapper = styled.footer`
  * {
    box-sizing: border-box;
  }
  box-sizing: border-box;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #eaeaea;
  margin: ${(props) => props.margin};
  -webkit-font-smoothing: antialiased;
`;

export const Content = styled.div`
  max-width: 1140px;
  margin: auto;
  font-family: 'Nunito Sans', 'Helvetica Neue', HelveticaNeue, Helvetica, Arial,
    sans-serif;
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 56px;
  margin-bottom: 28px;
  ${medias.extraLarge`margin: 24px 0;`}
`;

export const ColLeft = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  ${medias.extraLarge`flex-direction: column;`}
  ${medias.extraLarge`align-items: center;`}
`;

export const ColRight = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  ${medias.extraLarge`display: none;`}
`;

export const PrimaryLinkFooter = styled.a`
  ${fontSemiSmall};
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  color: #4a4a4a;
  ${medias.extraLarge`margin-bottom: 24px;`}
  ${medias.large`margin-bottom: 16px;`}
  transition: text-decoration ease .3s;

  &:hover {
    text-decoration: underline;
  }
`;

export const LinkDicas = styled(PrimaryLinkFooter)`
  margin-left: 32px;
  ${medias.extraLarge`margin-left: 0;`}
  ${medias.extraLarge`margin-bottom: 0;`}
  ${medias.large`margin-bottom: 16px;`}
`;

export const ProfessionalPlan = styled(PrimaryLinkFooter)`
  margin-left: 32px;
  ${medias.extraLarge`margin-left: 0;`}
  ${medias.extraLarge`margin-bottom: 0;`}
  ${medias.large`margin-bottom: 16px;`}
`;

export const VenderNaOLX = styled(PrimaryLinkFooter)`
  margin-left: 32px;
  ${medias.extraLarge`margin-left: 0;`}
  ${medias.extraLarge`margin-bottom: 0;`}
  ${medias.large`margin-bottom: 16px;`}
`;

export const SeccondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 40px;
  color: #4a4a4a;
  ${fontSmall};
  font-weight: 300;
  ${medias.extraLarge`flex-direction: column;`}
  ${medias.extraLarge`align-items: center;`}
  ${medias.large`margin-top: 16px;`}
`;

export const Policies = styled.div`
  display: flex;
  flex-direction: column;
  ${medias.extraLarge`align-items: center;`}
`;

export const FooterLink = styled.a`
  text-decoration: underline;
  color: #4a4a4a;
  font-weight: 300;
  ${medias.large`padding-top: 16px;`}
`;

export const Address = styled.address`
  margin-top: 8px;
  ${medias.extraLarge`text-align: center;`}
  ${medias.extraLarge`line-height: 180%;`}
  ${medias.large`margin-top: 24px;`}
`;

export const FooterLinksNav = styled.nav`
  display: block;
  ${medias.extraLarge`display: flex;`}
  ${medias.extraLarge`flex-direction: row;`}
  ${medias.extraLarge`justify-content: space-evenly;`}
  ${medias.extraLarge`width: 100%;`}
  ${medias.extraLarge`padding: 0 24px;`}
  ${medias.large`flex-flow: wrap;`}
`;

export const HideOnSmall = styled.span`
  ${medias.extraLarge`display: none;`}
`;
