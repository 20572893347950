import PropTypes from 'prop-types';
import styled from 'styled-components';

const InnerView = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  border-style: solid;
  border-width: 1px 0px 0px;
  border-color: rgb(242, 242, 242);
`;

const View = ({ lurker, ...props }) => (
  <InnerView data-lurker-detail={lurker} {...props} />
);

View.propTypes = {
  lurker: PropTypes.string, // deprecated prop lurker
  'data-lurker-detail': PropTypes.string, // lurker property to track click with given object detail
};

export default View;
