import { ReactNode } from 'react';
import styled from 'styled-components';
import { Category } from 'graphql/types';
import { CategoryContainer } from 'components/molecules';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }

  > * {
    flex-basis: 48%;
    display: flex;

    @media (min-width: 768px) {
      flex-basis: 33.3333333%;
    }

    @media (min-width: 1024px) {
      flex-basis: 25%;
    }
  }
`;

type Props = {
  categories: [Category];
};

const getCategories = (categories: [Category]): ReactNode =>
  categories?.map((category) => (
    <CategoryContainer key={category.slug} category={category} />
  ));

const CategoriesList = (props: Props) => {
  const { categories } = props;

  return (
    <Container data-synthetic="container_categories">
      {getCategories(categories)}
    </Container>
  );
};

export default CategoriesList;
