import { NextRouter, useRouter } from 'next/router';
import styled from 'styled-components';

const HOME_PAGE = '/s';
const ARTICLE_PAGE = '/s/article/[slug]';

type BackgroundProps = {
  pagePath: string;
};

const Square = styled.div`
  position: relative;
  background: #f28000;
  width: 212px;
  height: 212px;
  border-radius: 16px;
  @media (max-width: 600px) {
    width: 140px;
    height: 140px;
  }
`;

const Triangle = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 12px;
  background: linear-gradient(
    to bottom right,
    #fff 40%,
    #fff 46%,
    #8ce563 46%,
    #8ce563 100%
  );
  @media (max-width: 600px) {
    width: 140px;
    height: 140px;
  }
`;

const Circle = styled.div`
  position: relative;
  background: #6e0ad6;
  width: 215px;
  height: 215px;
  border-radius: 50%;
  @media (max-width: 600px) {
    width: 140px;
    height: 140px;
  }
`;

const DivLeft = styled.div`
  position: absolute;
  transform: rotate(10deg);
  left: -179px;

  @media (max-width: 600px) {
    display: none;
  }

  ${({ pagePath }: BackgroundProps) => {
    if (pagePath === HOME_PAGE) {
      return `
        top: 444px;
      `;
    }
    if (pagePath === ARTICLE_PAGE) {
      return `
        top: 166px;
      `;
    }
    return `
        display: none;
    `;
  }}
`;

const DivRight = styled.div`
  position: absolute;
  transform: rotate(10deg);
  right: -179px;
  @media (max-width: 600px) {
    right: -132px;
    transform: rotate(85deg);
  }

  ${({ pagePath }: BackgroundProps) => {
    if (pagePath === HOME_PAGE) {
      return `
        bottom: 336px;
        @media (max-width: 600px) {
          bottom: unset;
          top: 340px;
        }
      `;
    }
    if (pagePath === ARTICLE_PAGE) {
      return `
        bottom: 664px;
        @media (max-width: 600px) {
          display: none;
        }
      `;
    }
    return `
        bottom: 270px;
        @media (max-width: 600px) {
          display: none;
        }
    `;
  }}
`;

const getRandomInt = (max: number) =>
  Math.floor(Math.random() * Math.floor(max));

const defineForm = (router: NextRouter) => {
  if (router.asPath === HOME_PAGE) {
    return <Square />;
  }

  const random = getRandomInt(3);

  switch (random) {
    case 0:
    default:
      return <Square />;
    case 1:
      return <Circle />;
    case 2:
      return <Triangle />;
  }
};

const Background = () => {
  const router = useRouter();
  const form = defineForm(router);

  return (
    <>
      <DivLeft pagePath={router.pathname}>{form}</DivLeft>
      <DivRight pagePath={router.pathname}>{form}</DivRight>
    </>
  );
};

export default Background;
