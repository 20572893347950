import { useState } from 'react';
import { clickLink } from 'tracking/lurker';
import { FloatingContainer, Text } from './styles';

const ButtonAcessibility = () => {
  const [isExpanded, setExpanded] = useState(false);

  const redirectToLibrasServicePlatform = () => {
    clickLink('help_center_home', 'central_libras_service');
    window.open(process.env.NEXT_PUBLIC_URL_LIBRAS_SERVICE_PLATFORM, '_blank');
  };

  return (
    <FloatingContainer
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
      onClick={redirectToLibrasServicePlatform}
    >
      <img
        src="/help_center_images/acessibility.svg"
        alt="Símbolo de duas mãos fazendo movimento de libras dentro de um balão de mensagem"
      />

      {isExpanded && <Text>Atendimento em Libras</Text>}
    </FloatingContainer>
  );
};

export default ButtonAcessibility;
