import styled from 'styled-components';
import { thumbs } from 'tracking/lurker';
import LikeButton from 'components/molecules/like-button';

const WrapButtons = styled.div`
  margin: 1rem 0;
  margin-bottom: 0;
  display: inline-flex;
  > button {
    margin-right: 1rem;
  }
`;

type Props = {
  handleNextStep: Function;
  objectDetail: string;
  objectName: string;
};

const LikeFeedback = ({ handleNextStep, objectDetail, objectName }: Props) => {
  const handleClickEvent = (isLike: boolean) => () => {
    thumbs(isLike, objectDetail, objectName);
    handleNextStep(isLike);
  };

  return (
    <div>
      <WrapButtons>
        <LikeButton onClick={handleClickEvent(true)} />
        <LikeButton isUnlike onClick={handleClickEvent(false)} />
      </WrapButtons>
    </div>
  );
};

export default LikeFeedback;
