/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';

const PUBLIC_URL = process.env.PUBLIC_URL || 'http://localhost:9002';

const WrapImage = styled.div`
  padding-bottom: 1rem;
`;

const Legend = styled.div`
  color: #4a4a4a;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
  text-align: center;
`;

const ImageStyled = styled.img`
  max-width: 100%;
`;

const getSrc = (src: string) => {
  if (src.match(/^https?:\/\//)) {
    return src;
  }

  return `${PUBLIC_URL}${src}`;
};

const Image = (props: any) => {
  const text = props.alt || props.title;
  const src = getSrc(props.src || '');
  return (
    <WrapImage>
      <ImageStyled {...props} src={src} />
      <Legend>{text}</Legend>
    </WrapImage>
  );
};

export default Image;
