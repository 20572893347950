import styled from 'styled-components';

const TitleMedium = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #4a4a4a;
  font-weight: 700;
`;

export default TitleMedium;
