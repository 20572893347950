/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_HOME_COMPONENTS = gql`
  query {
    categories(
      sort: "position"
      filters: { parent: { id: { null: true } } }
      pagination: { pageSize: 500 }
    ) {
      data {
        id
        attributes {
          title
          position
          slug
          articles(pagination: { pageSize: 500 }) {
            data {
              id
            }
          }
          icon {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          categories(pagination: { pageSize: 500 }) {
            data {
              id
              attributes {
                articles(pagination: { pageSize: 500 }) {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
