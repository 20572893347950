import getLoginUrl from 'util/getLoginUrl';
import { TagAMarkdown } from 'components/atoms';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useLoggedUser } from 'hooks';
import { LoadingIcon } from '@olxbr/design-system';
import styled from 'styled-components';
import { clickLink, trackMetric } from 'tracking/lurker';
import { Alert } from '@olxbr/olx-cjs-component-lib';

type Props = {
  children: any;
};

const InlineLoading = styled(LoadingIcon)`
  width: 1em;
  height: 1em;
  display: inline;
`;

const PointerTagAMarkdown = styled(TagAMarkdown)`
  cursor: pointer;
`;

const AlertWrapper = styled.div`
  position: fixed;
  top: 70px;
  right: 30px;
  width: 290px;
`;

const olxCardInterestRouteQuery = 'send-olx-card-interest';

const OlxCardInterest = ({ children }: Props) => {
  const router = useRouter();
  const { user } = useLoggedUser();
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState<any>({
    visible: false,
    variant: undefined,
    message: undefined,
  });

  const getUserPublicAccountId = (): string | undefined => {
    if (user) {
      return user.publicAccountId;
    }
    return undefined;
  };

  const isUserLogged = () =>
    getUserPublicAccountId() !== null && getUserPublicAccountId() !== undefined;

  const sendOlxCardInterest = async (
    accountId: string | undefined,
  ): Promise<void> => {
    setLoading(true);
    if (accountId) {
      await trackMetric(
        'result',
        'sucesso_cadastro_interesse_usuario_logado',
        'central_article',
        { account_id_central: accountId },
      );
      setAlertState({
        visible: true,
        variant: 'success',
        message: 'Registramos seu interesse',
        hasClose: false,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (router.query && router.query[olxCardInterestRouteQuery]) {
      const accountId = getUserPublicAccountId();
      if (accountId) {
        trackMetric(
          'result',
          'sucesso_cadastro_interesse_usuario_deslogado',
          'central_article',
          { account_id_central: accountId },
        );
      }
      sendOlxCardInterest(getUserPublicAccountId());
    }
  }, [user]);

  const clickInterest = async () => {
    setLoading(true);
    if (isUserLogged()) {
      await sendOlxCardInterest(getUserPublicAccountId());
    } else {
      clickLink('link_interesse_cartao_usuario_deslogado', 'central_article');
      const loginUrl = await getLoginUrl(
        `${window.location.pathname}?${olxCardInterestRouteQuery}=true`,
      );
      (window as any).location = loginUrl;
    }
    setLoading(false);
  };

  return (
    <PointerTagAMarkdown
      onClick={clickInterest}
      data-testid="olx-card-interest-link"
    >
      {children}
      {loading ? <InlineLoading /> : null}
      {alertState.visible && (
        <AlertWrapper data-testid="olx-card-interest-alert">
          <Alert variant={alertState.variant}>{alertState.message}</Alert>
        </AlertWrapper>
      )}
    </PointerTagAMarkdown>
  );
};

OlxCardInterest.defaultProps = {};

export default OlxCardInterest;
