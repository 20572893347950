export const getEnv = (varName: string, defaultValue: any): string =>
  process.env[varName] || defaultValue;

export const getEnvAsInt = (varName: string, defaultValue: any) =>
  parseInt(getEnv(varName, defaultValue), 10);

export const getEnvAsBoolean = (varName: string, defaultValue: boolean) => {
  const env = getEnv(varName, defaultValue);

  return env === 'true' || env === '1';
};
