import styled from 'styled-components';
import { Divider } from 'components/atoms';
import { ItemDTO } from 'components/templates/search-bar';
import isWithModifier from 'util/isWithModifier';
import redirect from 'util/redirect';
import { clickLink } from 'tracking/lurker';
import MarkdownRender from 'components/organisms/markdown-render';

type AutoCompleteProps = {
  results: ItemDTO[];
};

type WrapperProps = {
  hasResults: boolean;
};

const WrapperAutoComplete = styled.div`
  background: #fff;
  border: 1px solid #6e0ad6;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 4px 0px 4px #f7f1fd;
  box-sizing: border-box;
  opacity: 0;
  position: absolute;
  top: 46px;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.5s, opacity 0.5s;
  width: 100%;
  z-index: 1;

  ${({ hasResults }: WrapperProps) =>
    hasResults &&
    `
    opacity: 1;
    transform: scaleY(1);
  `}

  &::before {
    background: #fff;
    content: '';
    display: block;
    margin-top: -8px;
    height: 8px;
    width: 100%;
  }
`;

const DividerStyled = styled(Divider)`
  margin: auto;
  margin-left: 1rem;
  margin-right: 2rem;
`;

const ContainerAutoComplete = styled.div`
  padding: 1rem;
  padding-top: 0;
  max-height: 200px;
  overflow-y: auto;
`;

const ItemLink = styled.a`
  color: #4a4a4a;
  display: block;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin: 16px 0;
  text-decoration: none;

  em {
    font-weight: bold;
  }

  &:last-child {
    margin-bottom: 0;
  }

  :hover {
    color: #6e0ad6;
  }
`;

const SearchBarAutoComplete = ({ results }: AutoCompleteProps) => {
  const handleClick = (event: any, data: any) => {
    if (data.position && data.slug) {
      clickLink('suggestion', 'central_search', data);
    } else {
      clickLink('suggestion', 'central_search');
    }

    const url = event.target.href || event.currentTarget.href;

    if (!isWithModifier(event)) {
      setTimeout(() => {
        redirect(url);
      }, 300);
    }
  };

  return (
    <WrapperAutoComplete
      data-syntethic="search_autocomplete"
      hasResults={results.length > 0}
    >
      <DividerStyled />
      <ContainerAutoComplete>
        {results.map(({ name, slug }, position) => (
          <ItemLink
            key={`${name}-${slug}`}
            onClick={(e) => handleClick(e, { slug, position })}
            href={`/s/article/${slug}`}
          >
            <MarkdownRender>{name}</MarkdownRender>
          </ItemLink>
        ))}
      </ContainerAutoComplete>
    </WrapperAutoComplete>
  );
};

export default SearchBarAutoComplete;
