import { useCallback, useEffect, useRef } from 'react';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import styled from 'styled-components';
import { useStateWithStorage } from 'hooks';
import { TitleWithLinks } from 'components/molecules';
import isComponentVisibleInScroll from 'util/isComponentVisibleInScroll';
import { loadUtilArticleComponent } from 'tracking/lurker';

type Props = {
  name: string;
  slug: string;
};

const DivContainer = styled.div`
  margin-right: 16px;
`;

const LastVisitedArticles = ({ name, slug }: Props) => {
  const [lastVisitedArticles, setLastVisitedArticles] = useStateWithStorage(
    '__lastVisitedArticles',
  );
  const componentRef = useRef(null);

  const checkIfComponentAppear = useCallback(
    throttle(() => {
      if (!isComponentVisibleInScroll(componentRef.current)) return;

      loadUtilArticleComponent(slug, 'load_ultimos_visitados');
      window.removeEventListener('scroll', checkIfComponentAppear);
    }, 500),
    [],
  );

  useEffect(() => {
    const uniqueLastArticles = uniqWith(
      [
        { name, slug, url: `/s/article/${slug}` },
        ...(lastVisitedArticles || []),
      ],
      isEqual,
    ).slice(0, 4);

    setLastVisitedArticles(uniqueLastArticles);

    window.addEventListener('scroll', checkIfComponentAppear);
    return () => window.removeEventListener('scroll', checkIfComponentAppear);
  }, []);

  if (!Array.isArray(lastVisitedArticles)) return null;

  const lastVisitedArticlesFiltered = lastVisitedArticles.filter(
    (article) => article.slug !== slug,
  );
  if (lastVisitedArticlesFiltered.length === 0) return null;

  return (
    <DivContainer ref={componentRef}>
      <TitleWithLinks
        title="Últimos visitados"
        eventName="ultimos_visitados"
        links={lastVisitedArticlesFiltered}
      />
    </DivContainer>
  );
};

export default LastVisitedArticles;
