import styled from 'styled-components';

const TagAMarkdown = styled.a`
  color: #6e0ad6;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

export default TagAMarkdown;
