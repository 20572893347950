import PropTypes from 'prop-types';

import HRule from 'components/atoms/HRule';
import SocialMediaTwitter from 'components/atoms/social-media/Twitter';
import SocialMediaFacebook from 'components/atoms/social-media/Facebook';
import SocialMediaYouTube from 'components/atoms/social-media/YouTube';
import SocialMediaLinkedIn from 'components/atoms/social-media/LinkedIn';
import SocialMediaInstagram from 'components/atoms/social-media/Instagram';

import {
  ajuda,
  dicasDeSeguranca,
  sobreOlx,
  copyRight,
  politicaDePrivacidade,
  politicaDeProtecaoAPropriedade,
  planoProfissional,
  venderNaOLX,
} from './config/staticUrl';

import * as Styled from './styles';

const Footer = ({ margin }) => (
  <Styled.Wapper margin={margin}>
    <Styled.Content>
      <Styled.FirstRow>
        <Styled.ColLeft>
          <Styled.PrimaryLinkFooter href={ajuda}>
            Ajuda e contato
          </Styled.PrimaryLinkFooter>
          <Styled.LinkDicas href={dicasDeSeguranca}>
            Dicas de segurança
          </Styled.LinkDicas>
          <Styled.VenderNaOLX target="_blank" href={venderNaOLX}>
            Vender na OLX
          </Styled.VenderNaOLX>
          <Styled.ProfessionalPlan target="_blank" href={planoProfissional}>
            Plano Profissional
          </Styled.ProfessionalPlan>
        </Styled.ColLeft>
        <Styled.ColRight>
          <SocialMediaFacebook />
          <SocialMediaYouTube />
          <SocialMediaLinkedIn />
          <SocialMediaInstagram />
          <SocialMediaTwitter />
        </Styled.ColRight>
      </Styled.FirstRow>
      <HRule />
      <Styled.SeccondRow>
        <Styled.Policies>
          <Styled.FooterLinksNav>
            <Styled.FooterLink href={sobreOlx}>Sobre a OLX</Styled.FooterLink>
            <Styled.HideOnSmall>,&nbsp;</Styled.HideOnSmall>
            <Styled.FooterLink href={copyRight}>
              Termos de uso
            </Styled.FooterLink>
            <Styled.HideOnSmall>,&nbsp;</Styled.HideOnSmall>
            <Styled.FooterLink href={politicaDePrivacidade}>
              Política de privacidade
            </Styled.FooterLink>
            <Styled.HideOnSmall>&nbsp;e&nbsp;</Styled.HideOnSmall>
            <Styled.FooterLink href={politicaDeProtecaoAPropriedade}>
              Proteção à Propriedade Intelectual
            </Styled.FooterLink>
          </Styled.FooterLinksNav>
          <Styled.Address>
            © Bom Negócio Atividades de Internet Ltda. Rua do Catete, 359,
            Flamengo - 22220-001 - Rio de Janeiro, RJ
          </Styled.Address>
        </Styled.Policies>
      </Styled.SeccondRow>
    </Styled.Content>
  </Styled.Wapper>
);

Footer.propTypes = {
  margin: PropTypes.string,
};

export default Footer;
