/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';

const StyledLink = styled.a`
  color: #6e0ad6;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  :hover {
    text-decoration: underline;
  }
`;

type Props = {
  href: string;
  children: any;
  [key: string]: any;
  className?: string;
};

const SimpleLink = ({ href, children, ...props }: Props) => (
  <StyledLink href={href} {...props}>
    {children}
  </StyledLink>
);

SimpleLink.defaultProps = {
  className: '',
};

export default SimpleLink;
