import { useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import { LikeFeedback, FormFeedback } from 'components/organisms';
import isComponentVisibleInScroll from 'util/isComponentVisibleInScroll';
import { loadUtilArticleComponent } from 'tracking/lurker';
import { ThanksForFeedback, Title } from './styles';

const FINAL_STEP = 2;

type Props = {
  title: string;
  objectName: string;
  objectDetail: string;
  maxCharacters?: number;
  options?: string[];
  // eslint-disable-next-line no-unused-vars
  onActionCallback?: (step: number) => void;
};

const FeedbackAction = ({
  title,
  objectDetail,
  objectName,
  maxCharacters,
  options,
  onActionCallback = () => null,
}: Props) => {
  const [step, setNextStep] = useState(0);
  const componentRef = useRef(null);

  const checkIfComponentAppear = useCallback(
    throttle(() => {
      if (!isComponentVisibleInScroll(componentRef.current)) return;

      loadUtilArticleComponent(objectDetail, 'load_artigo_util');
      window.removeEventListener('scroll', checkIfComponentAppear);
    }, 500),
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', checkIfComponentAppear);

    return () => window.removeEventListener('scroll', checkIfComponentAppear);
  }, []);

  const handleNextStep = () => {
    setNextStep(step + 1);

    onActionCallback(step + 1);
  };

  const handleNextStepLike = (isLike: boolean) => {
    const next = isLike ? 2 : 1;
    setNextStep(step + next);

    if (isLike) {
      onActionCallback(FINAL_STEP);
    }
  };

  const childStep = () => {
    switch (step) {
      case 0:
        return (
          <LikeFeedback
            handleNextStep={handleNextStepLike}
            objectDetail={objectDetail}
            objectName={objectName}
          />
        );
      case 1:
        return (
          <FormFeedback
            handleNextStep={handleNextStep}
            objectDetail={objectDetail}
            objectName={objectName}
            options={options}
            maxCharacters={maxCharacters}
          />
        );
      default:
        return (
          <ThanksForFeedback>Agradecemos o seu feedback</ThanksForFeedback>
        );
    }
  };

  return (
    <div ref={componentRef}>
      <Title>{title}</Title>
      {childStep()}
    </div>
  );
};

export default FeedbackAction;
