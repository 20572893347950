import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  background-color: transparent;
  border: 0;
  border: 1px solid var(--color-neutral-90);
  border-radius: var(--border-radius-pill);
  color: var(--color-neutral-130);
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  line-height: var(--font-lineheight-medium);
  padding: var(--spacing-0-5);
  transition: all ease var(--transition-duration-3);
  min-width: 135px;

  img {
    margin-right: var(--spacing-1);
  }
`;
