import styled from 'styled-components';
import { Button } from '@olxbr/design-system';
import { ReactNode } from 'react';

const ButtonStyled = styled(Button)`
  color: #f28000;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 108px;
`;

const Icon = styled.img`
  margin-right: 10px;
`;

type Props = {
  icon: string;
  children: ReactNode;
  onClick: Function;
};

const IconButton = ({ icon, children, onClick }: Props) => (
  <ButtonStyled outline onClick={onClick}>
    <Icon src={icon} alt="ícone" />
    {children}
  </ButtonStyled>
);

export default IconButton;
