const getDistanceToTop = (element: any) => {
  let y = 0;

  let currentElement = element;
  while (currentElement) {
    y +=
      currentElement.offsetTop -
      currentElement.scrollTop +
      currentElement.clientTop;
    currentElement = currentElement.offsetParent;
  }

  return y;
};

const isComponentVisibleInScroll = (ref: any): boolean => {
  if (!ref) return false;
  const scrollDistanceToTop = window.pageYOffset + window.innerHeight;
  const refDistanceToTop = getDistanceToTop(ref) + ref.offsetHeight;

  return scrollDistanceToTop >= refDistanceToTop;
};

export default isComponentVisibleInScroll;
