import Markdown from 'markdown-to-jsx';
import {
  Image,
  TagAMarkdown,
  TagBlockquoteMarkdown,
  TagDivMarkdown,
  TagH4Markdown,
  TagLIMarkdown,
  TagOLMarkdown,
  TagPMarkdown,
  TagULMarkdown,
  TagSpan,
  TagStrong,
} from 'components/atoms';
import SliderRenderer from 'components/organisms/slider-renderer';

type Props = {
  children: any;
  options?: any;
};

const defaultOptions = {
  overrides: {
    div: {
      component: TagDivMarkdown,
    },
    p: {
      component: TagPMarkdown,
    },
    a: {
      component: TagAMarkdown,
    },
    ul: {
      component: TagULMarkdown,
    },
    ol: {
      component: TagOLMarkdown,
    },
    li: {
      component: TagLIMarkdown,
    },
    h4: {
      component: TagH4Markdown,
    },
    img: {
      component: Image,
    },
    slider: {
      component: SliderRenderer,
    },
    blockquote: {
      component: TagBlockquoteMarkdown,
    },
    span: {
      component: TagSpan,
    },
    strong: {
      component: TagStrong,
    },
    // Experiment: {
    //   component: Experiment,
    // },
    // Variant: {
    //   component: Variant,
    // }
  },
};

const MarkdownRender = ({ children, options }: Props) => {
  const finalOptions = {
    overrides: {
      ...defaultOptions.overrides,
      ...options.overrides,
    },
  };

  return <Markdown options={finalOptions}>{children}</Markdown>;
};

MarkdownRender.defaultProps = {
  options: { overrides: {} },
};

export default MarkdownRender;
