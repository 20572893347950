import styled from 'styled-components';

const TagBlockquoteMarkdown = styled.blockquote`
  font-family: 'Nunito Sans', sans-serif;
  margin-inline-end: 0;
  margin-inline-start: 0;
  padding-left: 18px;
  margin: 20px 0;
  position: relative;

  @media (min-width: 1024px) {
    margin: 40px 0;
  }

  &:before {
    background: #4a4a4a;
    content: '';
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 3px;
  }

  > p {
    color: #4a4a4a;
    font-style: italic;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: 200;
    letter-spacing: 0.2px;
  }
`;

export default TagBlockquoteMarkdown;
