import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize('UA-70177409-32');
};

export const pageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname + window.location.search);
};
