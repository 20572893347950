const CHAT_PRO_AUTOS = '5733o000000oLpy';
const CHAT_PRO_IMOVEIS = '5733o000000oLpo';

export function userEmail() {
  if (typeof window !== 'undefined')
    return window.sessionStorage.getItem('userEmail');
}

export const chatPriOptions = {
  salesForceUrl: 'https://olx.my.salesforce.com',
  siteUrl: 'https://ajuda.olx.com.br/',
  chatId: '00Df4000000oD1h',
  chatName: 'FormularioCSPriChat',
  chatOptions: {
    displayHelpButton: false,
    enabledFeatures: ['LiveAgent'],
    entryFeature: 'LiveAgent',
    defaultMinimizedText: 'Chat',
    disabledMinimizedText: 'Chat indisponível',
    loadingText: 'Carregando',
    prepopulatedPrechatFields: {
      SuppliedEmail: userEmail(),
    },
    baseLiveAgentContentURL:
      'https://c.la1-c1-ia4.salesforceliveagent.com/content',
    deploymentId: '5720H000000W7jS',
    buttonId: '5730H000000WC00',
    baseLiveAgentURL: 'https://d.la1-c1-ia4.salesforceliveagent.com/chat',
    eswLiveAgentDevName:
      'EmbeddedServiceLiveAgent_Parent04I0H000000005RUAQ_16f6c57ecb2',
    isOfflineSupportEnabled: false,
    directToButtonRouting: () => {
      if (window.sessionStorage.getItem('isProAutos')) {
        return CHAT_PRO_AUTOS;
      }
      if (window.sessionStorage.getItem('isProImmobile')) {
        return CHAT_PRO_IMOVEIS;
      }
      return null;
    },
  },
};

export const chatPayOptions = {
  salesForceUrl: 'https://olxpay.my.salesforce.com',
  siteUrl: 'https://olxpay.my.salesforce-sites.com/',
  chatId: '00D3h000000DCnh',
  chatName: 'Chat_Integrado_Pay',
  chatOptions: {
    displayHelpButton: false,
    enabledFeatures: ['LiveAgent'],
    entryFeature: 'LiveAgent',
    defaultMinimizedText: 'Chat',
    loadingText: 'Carregando',
    baseLiveAgentContentURL:
      'https://c.la4-c1-ia4.salesforceliveagent.com/content',
    deploymentId: '5725d000000Rp0R',
    buttonId: '5735d000000RsOY',
    baseLiveAgentURL: 'https://d.la4-c1-ia4.salesforceliveagent.com/chat',
    eswLiveAgentDevName:
      'EmbeddedServiceLiveAgent_Parent04I8M000000000pUAA_181d3ea03d1',
    isOfflineSupportEnabled: false,
    prepopulatedPrechatFields: {
      Email: userEmail(),
    },
    extraPrechatFormDetails: [
      {
        label: 'Telefone',
        transcriptFields: ['SuppliedPhone'],
        displayToAgent: true,
      },
      {
        label: 'Email',
        transcriptFields: ['SuppliedEmail'],
        displayToAgent: true,
      },
      {
        label: 'CPF/CNPJ',
        transcriptFields: ['CPF_CNPJ__c'],
        displayToAgent: true,
      },
      {
        label: 'Assunto',
        displayToAgent: true,
      },
      {
        label: 'Nome',
        displayToAgent: true,
      },
    ],
  },
};
