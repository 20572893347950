import { useState, useEffect, createContext } from 'react';
import getUser from 'api/user-data';

export const UserContext = createContext(null);

type Props = {
  children: any;
};

const getLoggedUser = (setLoggedUser: Function) => {
  getUser()
    .then((response) => {
      if (response && response.data) {
        setLoggedUser(response.data);
      } else {
        setLoggedUser(null);
      }
    })
    .catch(() => {
      setLoggedUser(null);
    });
};

export const UserProvider = ({ children }: Props) => {
  const [loggedUser, setLoggedUser] = useState<any>(undefined);

  useEffect(() => {
    getLoggedUser(setLoggedUser);
  }, []);

  return (
    <UserContext.Provider value={loggedUser}>
      {children}
    </UserContext.Provider>
  );
};
