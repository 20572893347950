import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APIGW_URI || '',
  headers: {
    common: {
      'X-OLX-TEAM-KEY': process.env.NEXT_PUBLIC_APIGW_KEY || '',
    },
    post: {
      'Content-Type': 'application/json',
    },
  },
  withCredentials: true,
  timeout: 10000,
});

export default instance;
