import { RadioInput } from '@olxbr/design-system';

type InputRadioProps = {
  selected: string;
  onChange: Function;
  label: string;
  value: string;
};

const InputRadio = ({ selected, onChange, label, value }: InputRadioProps) => (
  <RadioInput
    name="option"
    value={value}
    type="radio"
    checked={selected === value}
    onChange={() => onChange(value)}
    label={label}
  />
);

export default InputRadio;
