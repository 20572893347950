import { gql } from '@apollo/client';

export const GET_ARTICLE_BY_SLUG = gql`
  query getArticle($slug: String!) {
    articleBySlug(slug: $slug) {
      data {
        id
        attributes {
          slug
          name
          body
          position
          relatedArticles {
            data {
              id
              attributes {
                name
                slug
              }
            }
          }

          category {
            data {
              id
              attributes {
                title
                slug
                parent {
                  data {
                    id
                    attributes {
                      title
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
