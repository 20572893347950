import { Fragment } from 'react';
import styled from 'styled-components';
import { Divider } from 'components/atoms';
import { ThemeLink } from 'components/molecules';

const DividerStyled = styled(Divider)`
  border-bottom-color: #e5e5e5;
`;

const WrapContentList = styled.div`
  margin: 40px 0 24px 0;
`;

type Props = {
  list: {
    title: string;
    id: number;
    url: string;
  }[];
  onClick?: Function;
};

const CategoryList = ({ list, onClick }: Props) => {
  const callback = onClick || (() => {}); // Atrumando a build

  return (
    <WrapContentList data-synthetic="list_link">
      {list.map((item: any, index: number) => (
        <Fragment key={item.id}>
          <ThemeLink
            title={item.title}
            url={item.url}
            linkProps={{ onclick: callback(item) }}
          />
          {index + 1 < list.length && <DividerStyled noMargin />}
        </Fragment>
      ))}
    </WrapContentList>
  );
};

CategoryList.defaultProps = {
  onClick: () => {},
};

export default CategoryList;
