import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #4a4a4a;
`;

export const ThanksForFeedback = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
  color: #4a4a4a;

  &::after {
    content: ' =)';
    color: #8ce563;
  }
`;
