import styled from 'styled-components';
import { Props } from '.';

type SubHeaderType = {
  onClick: any;
  isVisible: boolean;
};
export const SubHeaderOverlay = styled.div`
  background: rgba(0, 0, 0, 0.55);
  height: calc(100% - 60px);
  opacity: 0;
  top: 0px;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  transition: opacity 0.2s ease-out;
  width: 100%;
  z-index: 10000;

  ${({ isVisible }: SubHeaderType) =>
    isVisible &&
    `
    opacity: 1;
    pointer-events: auto;
  `}
`;

export const SubHeaderWrap = styled.div`
  background: #fff;
  height: 100%;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in;
  width: 300px;

  ${({ isVisible }: Props) => isVisible && 'transform: translateX(0);'}
`;

export const AvatarWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #d2d2d2;
  cursor: pointer;

  img {
    margin-right: var(--spacing-1);
  }
`;
