import { useLoggedUser } from 'hooks';
import { clickLink } from 'tracking/lurker';
import { User } from 'typings/user';
import getLoginUrl from 'util/getLoginUrl';
import getPictureFromUser from 'util/getPictureFromUser';
import isWithModifier from 'util/isWithModifier';
import redirect from 'util/redirect';
import { Avatar, Text } from '@olxbr/ds-web';
import { useEffect } from 'react';
import { validateIsCampana } from 'services/get-is-campana';
import { SubHeaderOverlay, SubHeaderWrap, AvatarWrap } from './styles';
import MyTicketsHeader from '../my-tickets-header';

export interface Props {
  isVisible: boolean;
  toggleVisibility?: Function;
}

const LoggedContent = ({ user }: { user: User }) => {
  const label = user ? user.firstName : 'Entrar';

  const handleClick = async (ev: any) => {
    ev.preventDefault();
    clickLink('entrar', 'central_home');

    const isEventWithModifier = isWithModifier(ev);

    const url = await getLoginUrl();
    if (url) {
      if (!isEventWithModifier) {
        setTimeout(() => {
          redirect(url);
        }, 100);
        return;
      }

      window.open(url);
    }
  };

  const action = user ? undefined : handleClick;

  return (
    <AvatarWrap onClick={action}>
      {user && (
        <Avatar
          size="32px"
          src={getPictureFromUser(user) ?? ''}
          alt="Uma imagem de um avatar representando perfil."
        />
      )}

      <Text.BodySmall weight="semibold">{label}</Text.BodySmall>
    </AvatarWrap>
  );
};

const SubHeader = ({ isVisible, toggleVisibility }: Props) => {
  const { user } = useLoggedUser();
  const handleClickInOverlay = (ev: any) => {
    if (ev.currentTarget !== ev.target) return;

    if (typeof toggleVisibility === 'function') {
      toggleVisibility();
    }
  };

  const getValidateIsCampana = async (email: string) => {
    await validateIsCampana(email);
  };

  useEffect(() => {
    if (user) {
      getValidateIsCampana(user.email);
    }
  }, [user]);

  const contentComponent = () => (
    <>
      <LoggedContent user={user} />
      {user && (
        <AvatarWrap>
          <MyTicketsHeader
            isLateralMenu
            onCallButtonClick={handleClickInOverlay}
          />
        </AvatarWrap>
      )}
    </>
  );

  return (
    <SubHeaderOverlay isVisible={isVisible} onClick={handleClickInOverlay}>
      <SubHeaderWrap isVisible={isVisible}>{contentComponent()}</SubHeaderWrap>
    </SubHeaderOverlay>
  );
};

SubHeader.defaultProps = {
  toggleVisibility: () => {},
};

export default SubHeader;
