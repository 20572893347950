/* eslint-disable react/no-danger */
import Head from 'next/head';
import { marked } from 'marked';

type Props = {
  faqs: [any]
}

const SeoFaq = ({ faqs } : Props) => {
  if (!(faqs?.length)) {
    return null;
  }

  const ld = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs.map((x: any) => ({
      '@type': 'Question',
      name: x.article.name,
      acceptedAnswer: {
        '@type': 'Answer',
        text: marked(x.article.body),
      },
    })),
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ld) }}
      />
    </Head>

  );
};

export default SeoFaq;
