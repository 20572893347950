import { useQuery } from '@apollo/client';
import { Loading } from 'components/atoms';
import { LinkList } from 'components/organisms';
import isWithModifier from 'util/isWithModifier';
import redirect from 'util/redirect';
import { clickLink } from 'tracking/lurker';
import { GET_CATEGORY_LIST } from 'graphql/queries';
import RecordFlattener from 'util/cmsRecordFlattener';

const CategoryList = () => {
  const flattener = new RecordFlattener();
  const { loading, error, data } = useQuery(GET_CATEGORY_LIST);
  const serializedCategories = flattener.flatten(data?.categories);

  if (loading) return <Loading />;
  if (error) return <div>Erro na leitura da página. Tente novamente.</div>;

  const list = serializedCategories.map((category: any) => ({
    ...category,
    url: `/s/tema/${category.slug}`,
  }));

  const handleClick = (category: any) => (ev: any) => {
    const eventName = category.slug.replace(/-/g, '_');
    const to = ev.target.href || ev.currentTarget.href;

    clickLink(eventName, 'central_category', {
      to,
    });

    if (!isWithModifier(ev)) {
      ev.preventDefault();

      setTimeout(() => {
        redirect(to);
      }, 300);
    }
  };

  return <LinkList list={list} onClick={handleClick} />;
};

export default CategoryList;
