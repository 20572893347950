import styled from 'styled-components';
import { SimpleLink } from 'components/atoms';

type Props = {
  article: {
    name: string;
    slug: string;
  };
};

const WrapperBefore = styled.div`
  display: inline-block;
  max-width: 240px;
  text-align: center;
  white-space: break-spaces;
`;

const Wrapper = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 14px 16px;
`;

const SimpleLinkStyled = styled(SimpleLink)`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  &:hover {
    text-decoration: none;
  }
`;

const FAQSliderItem = ({ article }: Props) => (
  <WrapperBefore>
    <Wrapper>
      <SimpleLinkStyled href={`/s/article/${article.slug}`}>
        {article.name}
      </SimpleLinkStyled>
    </Wrapper>
  </WrapperBefore>
);

export default FAQSliderItem;
