/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from 'styled-components';
import { Category } from 'graphql/types';
import { clickLink } from 'tracking/lurker';
import isWithModifier from 'util/isWithModifier';
import redirect from 'util/redirect';

const TotalOfArticles = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  margin: 0;
  margin-top: 16px;
`;

const InfoContainer = styled.div`
  @media only screen and (min-width: 768px) {
    flex: 1;
    align-self: flex-end;
  }
`;

const Name = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6e0ad6;
  margin: 0;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    color: #4a4a4a;
  }
`;

const Container = styled.div`
  cursor: pointer;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  height: 152px;
  display: inline-flex;
  margin: 7px 0;
  flex-direction: column;
  padding: 16px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    border-radius: 16px;
    width: 100%;
    height: 135px;
    flex-direction: row-reverse;
    margin: 16px 12px;
    padding: 16px 8px 16px 16px;

    :hover {
      border: 1px solid #6e0ad6;
      box-shadow: 0px 0px 0px 4px #f7f1fd;
    }
  }
`;

const Img = styled.img`
  max-width: 64px;
  max-height: 64px;
  @media only screen and (min-width: 768px) {
    max-width: 96px;
    max-height: 96px;
  }
`;

type Props = {
  category: Category;
};

const CategoryContainer = (props: Props) => {
  const { category } = props;
  const url = `/s/tema/${category.slug}`;

  const sendToCategoryPage = (event: any) => {
    clickLink(category.slug.replace(/-/g, '_'), 'central_home');

    if (!isWithModifier(event)) {
      setTimeout(() => {
        redirect(url);
      }, 300);
    }
  };

  const getCategoryAmmountOfArticles = (aCategory: any): number => {
    if (aCategory.categories.length) {
      return aCategory.categories.reduce(
        (prev: number, curr: any) => prev + curr.articles.length,
        0,
      );
    }

    return aCategory.articles.length;
  };

  return (
    <a
      href={url}
      style={{ textDecoration: 'none' }}
      onClick={sendToCategoryPage}
    >
      <Container>
        <Img
          src={category.icon?.url}
          alt={
            category.icon?.alternativeText ||
            `Icone da categoria ${category.title}`
          }
        />
        <InfoContainer>
          <TotalOfArticles>
            {getCategoryAmmountOfArticles(category)}{' '}
            {getCategoryAmmountOfArticles(category) > 1 ? 'Artigos' : 'Artigo'}
          </TotalOfArticles>
          <Name>{category.title}</Name>
        </InfoContainer>
      </Container>
    </a>
  );
};

export default CategoryContainer;
