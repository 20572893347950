import { useEffect, useState } from 'react';
import get from 'lodash/get';
import safelyParseJSON from '../util/safelyParseJson';

// Function copied from modernizr to test if storage has permissions to read/write
function hasStoragePermission(storage: any) {
  const key = '__test__';
  try {
    storage.setItem(key, key);
    storage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}

const validStorage = (storage: any) =>
  typeof get(storage, 'getItem') === 'function';

const defaultResponse = [null, () => {}];

const useStateWithStorage = (
  storageKey: string,
  storageType: 'localStorage' | 'sessionStorage' = 'localStorage',
): Array<any> => {
  if (typeof window === 'undefined') return defaultResponse;
  let storage: any = null;
  try {
    storage = window[storageType];
  } catch (e) {
    return defaultResponse;
  }

  if (!validStorage(storage)) return defaultResponse;
  if (!hasStoragePermission(storage)) return defaultResponse;

  const [value, setValue] = useState(
    safelyParseJSON(storage.getItem(storageKey) || ''),
  );

  useEffect(() => {
    try {
      if (value) {
        storage.setItem(storageKey, JSON.stringify(value));
      } else {
        storage.removeItem(storageKey);
      }
    } catch (e) {
      console.error(e);
    }
  }, [value]);

  return [value, setValue];
};

export default useStateWithStorage;
