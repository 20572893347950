import { useEffect, useState } from 'react';
import useSWR from 'swr';

const uri = 'https://api-shortdust.olx.com.br/v2/porteiro-pro';

const getIsUserPro = (url: string) =>
  fetch(url, {
    method: 'GET',
    credentials: 'include',
  })
    .then((r) => r.json())
    .catch(() => null);

type TypeHook = {
  isUserPro: boolean;
  isProAutos: boolean;
  isProImmobile: boolean;
};

const useUserIsPro = (user: any): TypeHook | undefined => {
  const [userIsPro, setUserIsPro] = useState<TypeHook | undefined>(undefined);

  const { data, error } = useSWR<TypeHook>(
    () => (user ? uri : null),
    getIsUserPro,
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (error) {
      setUserIsPro({
        isProAutos: false,
        isProImmobile: false,
        isUserPro: false,
      });
      return;
    }

    if (data === undefined) {
      return;
    }

    setUserIsPro(data);
  }, [data]);

  return userIsPro;
};

export default useUserIsPro;
