import fetch from 'isomorphic-unfetch';
import {
  ApolloClient,
  NormalizedCacheObject,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import cache from 'memory-cache';
import isServer from 'util/is-server';
import stringToHash from 'util/stringToHash';
import getConfig from 'next/config';

const fetcher = async (url: string, options: any) => {
  const keyToCache = stringToHash(options.body);
  let data = cache.get(keyToCache);
  if (!data) {
    const req = await fetch(url, options);
    data = await req.text();

    cache.put(keyToCache, data, 60 * 1000 * 60); // 1 hour
  }

  return {
    text: async () => data,
  };
};

export default function createApolloClient(
  initialState: NormalizedCacheObject = {},
  headers?: Record<string, string> | undefined,
) {
  const { serverRuntimeConfig } = getConfig();
  const graphqlUri = serverRuntimeConfig.graphqlUri || process.env.GRAPHQL_URI;

  const ssrMode = typeof window === 'undefined';

  if (!initialState || graphqlUri === '') {
    throw new Error('Missing parameter required for connection');
  }

  const link = createHttpLink({
    uri: graphqlUri,
    headers,
    // @ts-ignore
    fetch: isServer() ? fetcher : fetch,
  });

  return new ApolloClient({
    ssrMode,
    link,
    cache: new InMemoryCache().restore(initialState),
  });
}
