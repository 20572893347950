import { getEnv } from './getEnv';

const BASE_URL = getEnv(
  'SHORT_DUST_API_HOST',
  'https://api-shortdust.olx.com.br',
);

const getLoginToken = async (route?: string): Promise<string | null> => {
  const req = await fetch(`${BASE_URL}/v1/login-tokens`, {
    method: 'POST',
    body: JSON.stringify({ route }),
    headers: { 'Content-Type': 'application/json' },
  });

  if (req.status >= 200 && req.status < 300) {
    const { token } = await req.json();
    return token;
  }

  return null;
};

const getLoginUrl = async (route?: string): Promise<string | null> => {
  const token = await getLoginToken(route);

  if (!token) return null;
  return `https://conta.olx.com.br/acesso?returnToToken=${token}`;
};

export default getLoginUrl;
