import * as mobileUrls from './mobileUrls';

const urls = mobileUrls;

export const termosDeUso =
  'https://ajuda.olx.com.br/s/article/termos-e-condicoes-de-uso';
export const esqueciMinhaSenha = 'https://conta.olx.com.br/recuperacao-senha';
export const faleConosco = 'https://ajuda.olx.com.br';
export const home = 'https://www.olx.com.br/';
export const ajuda = 'https://www.olx.com.br/faq.htm';
export const notificacoes = 'https://conta.olx.com.br/notificacoes/';
export const sobreOlx = 'https://portal.olx.com.br/';
export const trocaSenha = 'https://conta.olx.com.br/nova-senha';
export const confirmarTelefone =
  'https://conta.olx.com.br/confirmacao-telefone/';
export const deleteAccount = 'https://atendimento.olx.com.br/contas/inativacao';
export const dicasDeSeguranca = 'https://www.olx.com.br/seguranca';
export const copyRight = 'https://www.olx.com.br/copyright.htm';
export const login = 'https://www3.olx.com.br/account/form_login';
export const meuPlano = 'https://planoprofissional.olx.com.br';
export const favoritos = 'https://www3.olx.com.br/account/favorites';
export const gerenciarPagamentos = 'https://conta.olx.com.br/pagamentos';
export const politicaDePrivacidade =
  'https://ajuda.olx.com.br/s/article/politica-de-privacidade';
export const politicaDeProtecaoAPropriedade =
  'https://ajuda.olx.com.br/s/article/protecao-a-propriedade';
export const minhaConta = 'https://www3.olx.com.br/account/form_userinfo';
export const planoProfissional = 'https://www.olx.com.br/planos';
export const venderNaOLX = 'https://www.olx.com.br/vender';
export const gerenciarComunicacoes = 'https://conta.olx.com.br/comunicacoes';
export const centralDeSeguranca = 'https://conta.olx.com.br/seguranca';
export const olxPay = 'https://wallet.olx.com.br';
export const vendasOLXPay = 'https://meus-pedidos.olx.com.br/minhas-vendas';
export const comprasOLXPay = 'https://meus-pedidos.olx.com.br/minhas-compras';

function getUrlByPlatform(link) {
  return mobileUrls[link];
}

export const getUrls = () => ({
  home: getUrlByPlatform('home'),
  busca: getUrlByPlatform('busca'),
  meusAnuncios: getUrlByPlatform('meusAnuncios'),
  chat: getUrlByPlatform('chat'),
  central: getUrlByPlatform('central'),
  inserirAnuncio: getUrlByPlatform('inserirAnuncio'),
});

export const { busca, meusAnuncios, chat, inserirAnuncio, sair } = urls;
