/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';
import { FAQSliderItem } from 'components/molecules';

const HideScroll = styled.div`
  position: relative;

  &:after {
    background: #fff;
    bottom: 0;
    content: '';
    display: block;
    height: 8px;
    position: absolute;
    width: 100%;
  }
`;

const WrapperContainer = styled.div`
  padding: 12px 0;
  overflow: scroll;
`;

const WrapperSpace = styled.div`
  display: inline-block;
  white-space: nowrap;
  padding-bottom: 12px;

  > div {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

type ArticleType = {
  name: string;
  id: number;
  slug: string;
};

type Props = {
  articles: ArticleType[];
};

const FAQSlider = ({ articles }: Props) => (
  <HideScroll>
    <WrapperContainer>
      <WrapperSpace>
        {articles.map((article) => (
          <FAQSliderItem key={article.id} article={article} />
        ))}
      </WrapperSpace>
    </WrapperContainer>
  </HideScroll>
);

export default FAQSlider;
