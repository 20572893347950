import { useState } from 'react';
import { updateBodyOverflow } from 'util/updateBodyOverflow';
import isWithModifier from 'util/isWithModifier';
import redirect from 'util/redirect';
import { clickLink } from 'tracking/lurker';
import SubHeader from 'components/templates/sub-header';
import UserMenuDesktop from 'components/templates/user-menu-desktop';
import MyTicketsHeader from 'components/templates/my-tickets-header';
import {
  HeaderStyled,
  ColHeader,
  HamburguerStyled,
  OLXLogo,
  Title,
} from './styles';

const Header = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleToggleVisibility = () => {
    const overflowValue = isVisible ? 'auto' : 'hidden';
    setIsVisible(!isVisible);
    updateBodyOverflow(overflowValue);
  };

  const handleClickLogo = (ev: any) => {
    clickLink('home_logo', 'central_header');

    if (!isWithModifier(ev)) {
      ev.preventDefault();
      setTimeout(() => {
        redirect('/s/');
      }, 300);
    }
  };

  return (
    <>
      <HeaderStyled>
        <ColHeader xs={2.4}>
          <HamburguerStyled onClick={handleToggleVisibility} />
          <OLXLogo
            href="/s/"
            onClick={handleClickLogo}
            onAuxClick={handleClickLogo}
            size={42}
          />
          <Title>Central de ajuda</Title>
        </ColHeader>
        <ColHeader right xs={1}>
          <MyTicketsHeader />
        </ColHeader>
        <ColHeader right xs={0.5}>
          <UserMenuDesktop />
        </ColHeader>
      </HeaderStyled>
      <SubHeader
        isVisible={isVisible}
        toggleVisibility={handleToggleVisibility}
      />
    </>
  );
};

export default Header;
