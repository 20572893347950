import gql from 'graphql-tag';

export const GET_ARTICLES_BY_CATEGORY_ID = gql`
  query getCategories($categoryId: ID) {
    articles(
      sort: "position"
      filters: { category: { id: { eq: $categoryId } } }
      pagination: { start: 0, limit: 100 }
    ) {
      data {
        id
        attributes {
          slug
          name
          category {
            data {
              id
            }
          }
        }
      }
      meta {
        pagination {
          pageSize
          page
          pageCount
          total
        }
      }
    }
  }
`;
