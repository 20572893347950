import styled from 'styled-components';

const Strong = styled.strong`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
`;

export default Strong;
