/* eslint-disable class-methods-use-this */
import getWindowLib from './getWindowLib';

declare global {
  interface DisputeBridgeJS {
    isWebView: () => boolean;
    openUrl: (url: string) => void;
    showToast: (message: string) => void;
    getAbTests: () => string;
    close: () => void;
    sendToLurker: (
      eventType: string,
      objectName: string,
      objectDetail: string,
      lurkerParams: string,
      data: string,
    ) => void;
  }

  interface PostMessage {
    postMessage: (arg: unknown) => void;
  }

  interface IosBridge {
    sendToLurker: PostMessage;
    close: PostMessage;
    openUrl: PostMessage;
  }

  interface Webkit {
    messageHandlers: IosBridge | undefined;
  }

  interface Window {
    DisputesJsBridge: DisputeBridgeJS | undefined;
    webkit: Webkit | undefined;
  }
}

export default class BridgeJS {
  private static instance = new BridgeJS();

  get bridge() {
    return getWindowLib('DisputesJsBridge');
  }

  get navigator() {
    return getWindowLib('navigator');
  }

  public static getInstance(): BridgeJS {
    return this.instance;
  }

  public isWebView(): boolean {
    return this.isAndroidWebView() || this.isIosWebView();
  }

  public isAndroidWebView(): boolean {
    return this.bridge?.isWebView?.() || false;
  }

  public getPlatform(isMobile = false) {
    if (this.isAndroidWebView()) {
      return 'android';
    }
    if (this.isIosWebView()) {
      return 'ios';
    }
    return isMobile ? 'msite' : 'web';
  }

  public isIosWebView(): boolean {
    return this.navigator?.userAgent?.includes('OLX-Source/ios') || false;
  }

  public openUrl(url: string): void {
    if (this.isIosWebView()) {
      window?.webkit?.messageHandlers?.openUrl.postMessage(url);
    } else if (this.isAndroidWebView()) {
      this.bridge?.openUrl?.(url);
    } else {
      window.location.href = url;
    }
  }

  public showToast(message: string): void {
    return this.bridge?.showToast?.(message);
  }

  public getAbTests(): string | undefined {
    return this.bridge?.getAbTests?.();
  }

  public close(): void {
    return this.bridge?.close?.();
  }

  public sendToLurker(
    eventType: string,
    objectName: string,
    objectDetail: string,
    lurkerParams: string,
    data: string,
  ): void {
    this.bridge?.sendToLurker?.(
      eventType,
      objectName,
      objectDetail,
      lurkerParams,
      data,
    );
  }
}
