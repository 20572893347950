import { formatUrl } from 'util/format-url';

const BASE_URL = process.env.NEXT_PUBLIC_APIGW_URI ?? '';
const APIGW_KEY = process.env.NEXT_PUBLIC_APIGW_KEY ?? '';
const PARAMS_URL = `v2/me?X-OLX-TEAM-KEY=${APIGW_KEY}`;
export const fetcher = async (url: string) =>
  fetch(url, {
    headers: {
      accept: '*/*',
      'accept-language': 'pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7',
    },
    referrerPolicy: 'no-referrer-when-downgrade',
    body: null,
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
  })
    .then((r) => r.json())
    .catch(() => null);

export const accountsURL = formatUrl(BASE_URL, PARAMS_URL);
