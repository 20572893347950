import { ReactNode, useContext, useEffect, useState } from 'react';
import { Variant, Experiment } from '@olxbr/react-experimentation';
import { MarkdownRender, NoticeModal } from '../../organisms';

import { TagAMarkdown } from '../../atoms';
import { BannerContext } from '../../context';
import { clickLink, visibleTrack } from '../../../tracking/lurker';

type BannerProps = {
  href: string;
  children: ReactNode;
  bannerContent: string;
};

const BannerLink = ({ href, children, bannerContent }: BannerProps) => (
  <TagAMarkdown
    href={href}
    onClick={() => {
      clickLink('click_banner_alta_prioridade', 'central_home', {
        href,
        bannerContent,
      });
    }}
  >
    {children}
  </TagAMarkdown>
);

const getMarkdownMessage = (content: string) => (
  <MarkdownRender
    options={{
      overrides: {
        a: {
          component: BannerLink,
          props: { bannerContent: content },
        },
      },
    }}
  >
    {content}
  </MarkdownRender>
);

const IncidentBanner = () => {
  const { banners } = useContext(BannerContext);
  const [showContainer, setshowContainer] = useState(true);

  const closeBanner = () => {
    clickLink('fechar_banner_alta_prioridade', 'central_home', {
      content: banner?.content ?? '',
    });

    setshowContainer(false);
  };

  const banner = banners?.find((b) => b.incident);

  useEffect(() => {
    if (banner && showContainer) {
      visibleTrack('visible_banner_alta_prioridade', 'central_home', {
        content: banner.content,
      });
    }
  }, [banner, showContainer]);

  if (!banner) {
    return null;
  }

  return (
    <Experiment name="TesteFakeDoorBotaoCentralAjuda">
      <Variant value="control" default>
        <NoticeModal
          title={banner?.title ?? ''}
          visible={showContainer}
          onCloseHandle={closeBanner}
        >
          {getMarkdownMessage(banner?.content ?? '')}
        </NoticeModal>
      </Variant>
      <Variant value="A">
        <NoticeModal
          title={banner?.title ?? ''}
          visible={showContainer}
          onCloseHandle={closeBanner}
          buttonMessage="Ok"
        >
          {getMarkdownMessage(banner?.content ?? '')}
        </NoticeModal>
      </Variant>
    </Experiment>
  );
};

export default IncidentBanner;
