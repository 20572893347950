import { useEffect, useState } from 'react';
import { safelyParseJSON } from 'util/safely-parse-json';

// eslint-disable-next-line import/prefer-default-export
export const useStateWithSessionStorage = (sessionStorageKey: string) => {
  const [value, setValue] = useState(() => {
    if (typeof window !== 'undefined') {
      return safelyParseJSON(window.sessionStorage.getItem(sessionStorageKey));
    }
    return null;
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (value) {
        window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(value));
      } else {
        window.sessionStorage.removeItem(sessionStorageKey);
      }
    }
  }, [sessionStorageKey, value]);

  return [value, setValue];
};
