import styled from 'styled-components';
import { Title, TagPMarkdown } from 'components/atoms';
import redirect from 'util/redirect';

const Container = styled.div`
  margin: 0px 8px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TagP = styled(TagPMarkdown)`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  text-align: center;

  /* Primary / Graphite */
  color: #4a4a4a;
  max-width: 609px;
`;

const Button = styled.button`
  margin: auto;
  margin-top: 32px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 32px;

  /* White */
  background: #ffffff;
  /* Primary / Orange */
  border: 1px solid #f28000;
  box-sizing: border-box;
  border-radius: 100px;

  /* Body 1 / Semibold (600) */
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  /* Primary / Orange */
  color: #f28000;
  cursor: pointer;
`;

const Img = styled.img`
  max-width: 100%;
`;

const ButtonImage = styled.img`
  width: 24px;
  height: 24px;
  margin: 0 8px;
`;

type ErrorProps = {
  title: string;
  subtitle: string;
  imgSrc: string;
};

const ErrorComponent = ({ title, subtitle, imgSrc }: ErrorProps) => (
  <Container>
    <Img src={imgSrc} alt="Imagem Ilustrativa" />
    <Title>{title}</Title>
    <TagP>{subtitle}</TagP>
    <Button
      onClick={(e) => {
        redirect('/s');
        e.preventDefault();
      }}
    >
      <ButtonImage src="/help_center_images/arrow-up-left.svg" alt="img" />
      Voltar para o início
    </Button>
  </Container>
);

export default ErrorComponent;
