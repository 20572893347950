import styled from 'styled-components';
import { LoadingIcon } from '@olxbr/design-system';

const LoadingStyled = styled.div`
  text-align: center;
  padding: 1rem;
`;

const Loading = () => (
  <LoadingStyled>
    <LoadingIcon />
  </LoadingStyled>
);

export default Loading;
