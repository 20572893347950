import styled from 'styled-components';

export const FloatingContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  position: fixed;
  padding: 1rem 0.5rem;
  z-index: 200;
  right: 0;
  top: 40%;
  border-radius: 8px 0px 0px 8px;
  border-top: 1px solid #cfd4dd;
  border-bottom: 1px solid #cfd4dd;
  border-left: 1px solid #cfd4dd;
  background: #ffffff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);

  @media (max-width: 375px) {
    top: 50%;
  }

  @media (min-width: 1440px) {
    top: 50%;
  }

  &:hover {
    opacity: 0.98;
    transform: scale(1.005);
  }
`;

export const Text = styled.p`
  color: #2c38c1;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Nunito Sans;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  margin: 0.875rem 0;
`;
