import styled from 'styled-components';

const TagDivMarkdown = styled.div`
  margin-bottom: 40px;
  font-family: 'Nunito Sans', sans-serif;
  p:first-child {
    margin-block-start: 0px;
  }
`;

export default TagDivMarkdown;
