import {
  MouseEvent,
  FormEvent,
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import throttle from 'lodash/throttle';

import styled from 'styled-components';
import { SEARCH_PUBLISHED_ARTICLES } from 'graphql/queries';
import { SearchBarIcon } from 'components/atoms';
import SearchBarAutoComplete from 'components/organisms/search-bar-auto-complete';
import { clickLink, searchTrackResult, showTrack } from 'tracking/lurker';
import RecordFlattener from 'util/cmsRecordFlattener';

type WrapperProps = {
  hasResults: boolean;
};

const Section = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  transition: border-radius 0.5s;
  width: 100%;

  ${({ hasResults }: WrapperProps) =>
    hasResults && 'border-radius: 8px 8px 0 0;'}
  :focus-within {
    border-color: #6e0ad6;
    box-shadow: 0px 0px 0px 4px #f7f1fd;
  }
`;

const WrapperSearchBox = styled.form`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

const InputSearch = styled.input`
  border: 0;
  color: #4a4a4a;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin: 0 14px;
  min-width: -webkit-fill-available;
  width: 100%;

  &::placeholder {
    color: #999999;
    font-family: 'Nunito Sans', sans-serif;
  }
`;

type Props = {
  className?: string;
};

export type ItemDTO = {
  id: string;
  name: string;
  slug: string;
};

type ResponseDTO = {
  searchArticle: ItemDTO[];
};

let lastResults: ItemDTO[] = [];

const SearchBar = (props: Props) => {
  const flattener = new RecordFlattener();
  const { className } = props;
  const [value, setValue] = useState('');
  const [autoCompleteVisible, setAutoCompleteVisible] = useState(false);
  const element = useRef(null);

  const [getSearch, { data, loading, error }] = useLazyQuery<
    ResponseDTO,
    { query: string }
  >(SEARCH_PUBLISHED_ARTICLES);

  const searchArticle = flattener.flatten(data?.searchArticle);

  useEffect(() => {
    const onClick = (ev: any) => {
      let target = ev && ev.target;
      while (target.parentNode) {
        if (target === element.current) {
          setAutoCompleteVisible(true);
          return;
        }
        target = target.parentNode;
      }
      setAutoCompleteVisible(false);
    };

    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  const throttleCallback = useCallback(
    throttle((newValue) => {
      if (newValue.length < 3) return;
      getSearch({
        variables: {
          query: newValue,
        },
      });

      searchTrackResult(newValue);
    }, 600),
    [],
  );

  let results: ItemDTO[] = [];
  if (loading) results = lastResults;

  if (
    !loading &&
    !error &&
    searchArticle &&
    value.length > 0 &&
    autoCompleteVisible
  ) {
    results = searchArticle as ItemDTO[];
    lastResults = results;

    if (results.length > 0) {
      showTrack('visible', 'central_search', {
        quantity: results.length,
      });
    }
  }
  const hasResults = results.length > 0 && value.length > 0;

  const handleSubmit = (
    event:
      | MouseEvent<HTMLButtonElement, MouseEvent>
      | FormEvent<HTMLDivElement>,
  ): void => {
    event.preventDefault();

    clickLink('button', hasResults ? 'close' : 'central_search', {
      searchText: value,
    });

    if (hasResults) {
      setValue('');
    }
  };

  const handleClickInput = () => clickLink('input', 'central_search');

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const val = ev.target.value;
    setValue(val);
    throttleCallback(val);
  };

  return (
    <Section className={className} ref={element}>
      <Wrapper onSubmit={handleSubmit} hasResults={hasResults}>
        <WrapperSearchBox>
          <InputSearch
            data-synthetic="search_input"
            placeholder="Ex: como reativar minha conta na OLX"
            onClick={handleClickInput}
            value={value}
            onChange={handleChange}
          />
        </WrapperSearchBox>
        <SearchBarIcon onClick={handleSubmit} hasResults={hasResults} />
      </Wrapper>
      <SearchBarAutoComplete results={results} />
    </Section>
  );
};

SearchBar.defaultProps = {
  className: '',
};

export default SearchBar;
