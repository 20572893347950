import styled from 'styled-components';

export const Wrapper = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: space-between;
  display: flex;

  &:hover {
    color: var(--color-secondary-100) !important;

    span {
      color: var(--color-secondary-100) !important;
    }

    path {
      fill: var(--color-secondary-100) !important;
    }
  }

  &:focus,
  &:active {
    color: var(--color-neutral-130) !important;

    span {
      color: var(--color-neutral-130) !important;
    }

    path {
      fill: var(--color-neutral-130) !important;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;
