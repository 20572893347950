import { gtmTriggerEvent } from 'lib/gtm';
import { Dispatch, SetStateAction } from 'react';

const addEventHandlers = (
  embeddedSvc: any,
  setAgentAvailable: Dispatch<SetStateAction<boolean>>,
  setChatInited: Dispatch<SetStateAction<boolean>>,
) => {
  if (!embeddedSvc) return;

  const onSettingsCallCompleted = ({ isAgentAvailable }: any) => {
    if (isAgentAvailable) {
      gtmTriggerEvent('load_contact_options_agent_available');
      setAgentAvailable(true);
    } else {
      gtmTriggerEvent('load_contact_options_agent_not_available');
      setAgentAvailable(false);
    }

    setChatInited(true);
  };

  embeddedSvc.addEventHandler(
    'onSettingsCallCompleted',
    onSettingsCallCompleted,
  );

  embeddedSvc.addEventHandler('onChatRequestSuccess', () => {
    gtmTriggerEvent('load_contact_option_load_chat');
  });

  embeddedSvc.addEventHandler('onChatEstablished', () =>
    gtmTriggerEvent('view_contact_options_chat'),
  );

  embeddedSvc.addEventHandler('onChatEndedByChasitor', () => {
    gtmTriggerEvent('click_contact_options_end_chat');
  });

  embeddedSvc.addEventHandler('onChatEndedByAgent', () => {
    gtmTriggerEvent('end_contact_options_chat_ended_by_agent');
  });

  embeddedSvc.addEventHandler('onIdleTimeoutOccurred', () =>
    gtmTriggerEvent('timer_contact_options_chat_expired'),
  );

  embeddedSvc.addEventHandler('afterDestroy', () => {
    gtmTriggerEvent('click_contact_options_close_chat');
  });
};

export default addEventHandlers;
