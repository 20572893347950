import { Row, Col, Logo, Hamburger } from '@olxbr/design-system';
import styled from 'styled-components';

export const HeaderStyled = styled(Row)`
  width: 100%;
  border-bottom-style: solid;
  border-bottom-color: #d2d2d2;
  border-bottom-width: 1px;
  margin-left: 0 !important; // Must important to replace Row style
  margin-right: 0 !important;

  @media only screen and (max-width: 600px) {
    width: calc(100% + 1em);
  }
`;

export const ColHeader = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  height: 64px;
  padding: 0 1.25rem !important;

  ${({ right }: { right: boolean }) =>
    right &&
    `
    justify-content: flex-end;
    display: none;

    @media (min-width: 1024px) {
      display: flex;
    }
  `}
`;

export const HamburguerStyled = styled(Hamburger)`
  margin-right: 0;

  > div,
  > div::after,
  > div::before {
    width: 20px;
    min-width: 20px;
  }

  > div::after {
    top: 6px;
  }

  > div::before {
    top: -6px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const OLXLogo = styled(Logo)`
  * {
    margin-top: 0px;
  }
  svg {
    vertical-align: middle;
  }
`;

export const Title = styled(Col)`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-left: 0.5rem;
  letter-spacing: 0.3px;
  color: #4a4a4a;
`;
