import { useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Button as OlxButton } from '@olxbr/design-system';
import Link from 'next/link';
import { throttle } from 'lodash';
import { TitleMedium } from 'components/atoms';
import isComponentVisibleInScroll from 'util/isComponentVisibleInScroll';
import { visibleTrack } from 'tracking/lurker';
import handleClick from 'util/handleClick';

const Container = styled.div`
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0 8px;
  padding-top: 24px;
  margin-top: 40px;
  -webkit-font-smoothing: antialiased;
  div {
    text-align: center;
  }
  @media only screen and (max-width: 601px) {
    padding-bottom: 24px;
    margin-bottom: 8px;
  }
  @media only screen and (min-width: 601px) {
    display: flex;
    height: 108px;
    max-height: 108px;
    width: 704px;
    max-width: 704px;
    margin: auto;
    padding: 0;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    div {
      margin-right: 12px;
    }
  }
`;

const Button = styled(OlxButton)`
  margin: auto;
  margin-top: 16px;
  height: 49px;
  background-color: #f28000;
  @media only screen and (min-width: 601px) {
    margin: 0;
    max-width: 174px;
    margin-left: 24px;
    font-weight: 600;
  }
`;

const ContactUs = () => {
  const componentRef = useRef(null);

  const checkIfComponentAppear = useCallback(
    throttle(() => {
      if (!isComponentVisibleInScroll(componentRef.current)) return;

      visibleTrack('contact_container', 'central_home');
      window.removeEventListener('scroll', checkIfComponentAppear);
    }, 500),
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', checkIfComponentAppear);
  }, []);

  return (
    <Container ref={componentRef}>
      <TitleMedium>Não encontrou o que procurava?</TitleMedium>
      <Link href="/fale-conosco" passHref>
        <Button
          onClick={(event: any) =>
            handleClick(event, 'faleconosco', 'central_home')
          }
        >
          Fale com a gente
        </Button>
      </Link>
    </Container>
  );
};

export default ContactUs;
