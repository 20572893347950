import styled from 'styled-components';

const H1 = styled.h1`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height, or 122% */
  text-align: center;

  /* Primary / Graphite */
  color: #4a4a4a;
`;

export default H1;
