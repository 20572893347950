import styled from 'styled-components';

const SearchIcon = styled.button`
  align-items: center;
  background: none;
  border-radius: 0 8px 8px 0;
  border: 0;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 0 14px;
  :hover {
    cursor: pointer;
  }
`;

const SearchBarIcon = ({ hasResults, onClick }) => {
  const filename = hasResults
    ? '/help_center_images/close-icon.svg'
    : '/help_center_images/search-icon.svg';

  return (
    <SearchIcon type="button" onClick={onClick}>
      <img src={filename} alt="ícone busca" />
    </SearchIcon>
  );
};

SearchBarIcon.defaultProps = {
  onClick: () => {},
};

export default SearchBarIcon;
