import styled from 'styled-components';

const Span = styled.span`
  font-family: 'Nunito Sans', sans-serif;
  line-height: 24px;
  size: 16px;
  font-weight: 400;
`;

export default Span;
