/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_FAQ_ARTICLES = gql`
  query {
    faqs(sort: "position", pagination: { limit: 5 }) {
      data {
        id
        attributes {
          article {
            data {
              id
              attributes {
                slug
                name
              }
            }
          }
        }
      }
    }
  }
`;
