import styled from 'styled-components';

const ArticleTitle = styled.h2`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin: 0.5rem 0;
  line-height: 32px;
  color: #4a4a4a;

  @media (max-width: 600px) {
    margin-bottom: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export default ArticleTitle;
