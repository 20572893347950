/* eslint-disable camelcase */
import BridgeJS from 'util/bridgeWebView';

declare global {
  interface Window {
    beforeLurkerLoadEvents: any[];
    Lurker: LurkerType;
  }
}

const bridge = BridgeJS.getInstance();

const defaultLurkerOptions = () => ({
  source: bridge.getPlatform(),
});

type TrackData = {
  [key: string]: string | number;
};

type TrackObject = {
  object_name: string;
  event_type: string;
  object_detail: string;
  [key: string]: any;
  data?: TrackData;
};

interface LurkerType {
  send: Function;
}

const sendToLurker = async (options: TrackObject) => {
  const metrics = {
    ...defaultLurkerOptions(),
    ...options,
  };

  try {
    if (BridgeJS.getInstance().isWebView()) {
      const lurkerParams = JSON.stringify({});
      const lurkerData = JSON.stringify(metrics.data);
      BridgeJS.getInstance().sendToLurker(
        metrics.event_type,
        metrics.object_name,
        metrics.object_detail,
        lurkerParams,
        lurkerData,
      );
    } else if (window.Lurker) {
      window.Lurker.send(metrics);
    } else {
      if (!window.beforeLurkerLoadEvents) window.beforeLurkerLoadEvents = [];
      window.beforeLurkerLoadEvents.push(metrics);
    }
  } catch (e: any) {
    console.warn(e.message);
  }
};

const track = async (options: TrackObject) => {
  sendToLurker(options);
};

export const trackMetric = (
  eventType: string,
  objectDetail: string,
  objectName: string,
  data: TrackData = {},
) =>
  track({
    event_type: eventType,
    object_name: objectName,
    object_detail: objectDetail,
    data,
  });

export const thumbs = (
  up: boolean,
  object_detail: string,
  objectName: string,
) =>
  track({
    event_type: 'click',
    object_name: objectName,
    object_detail: `thumbs${up ? 'up' : 'down'}`,
    data: {
      article_name: object_detail,
      info: object_detail,
    },
  });

export const dislikeFeedback = (
  feedback: string,
  selectedOption: string,
  objectDetail: string,
  objectName: string,
) =>
  track({
    event_type: 'click',
    object_name: objectName,
    object_detail: 'send_dislike_feedback',
    data: {
      info: objectDetail,
      article_name: objectDetail,
      feedback,
      selected_option: selectedOption,
    },
  });

export const pageView = (objectName: string, objectDetail: string = 'load') =>
  track({
    event_type: 'page_view',
    object_name: objectName,
    object_detail: objectDetail,
  });

export const readingArticleTime = (slug: string, time: number) =>
  track({
    event_type: 'timer',
    object_name: 'central_article',
    object_detail: 'reading',
    data: {
      article_slug: slug,
      time,
    },
  });

export const loadUtilArticleComponent = (slug: string, objectDetail: string) =>
  track({
    event_type: 'page_view',
    object_name: 'central_article',
    object_detail: objectDetail,
    data: {
      slug,
    },
  });

export const clickLink = (
  objectDetail: string,
  objectName: string,
  data: TrackData = {},
) =>
  track({
    event_type: 'click',
    object_name: objectName,
    object_detail: objectDetail,
    data,
  });

export const searchTrackResult = (searchValue: string) =>
  track({
    event_type: 'result',
    object_name: 'central_search',
    object_detail: 'result',
    data: {
      searchValue,
    },
  });

export const showTrack = (
  objectDetail: string,
  objectName: string,
  data: TrackData = {},
) =>
  track({
    event_type: 'show',
    object_name: objectName,
    object_detail: objectDetail,
    data,
  });

export const visibleTrack = (
  objectDetail: string,
  objectName: string,
  data: TrackData = {},
) =>
  track({
    event_type: 'visible',
    object_name: objectName,
    object_detail: objectDetail,
    data,
  });
