import styled from 'styled-components';

export const Box = styled.a`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: transparent;
  cursor: pointer;
  transition: background-color ease 0.3s;

  :not(:last-child) {
    margin-right: 16px;
  }

  .socialIcon {
    fill: #999999;
  }

  :hover {
    background-color: ${(props) => props.bgColor};

    .socialIcon {
      fill: ${(props) => props.fill};
    }
  }
`;

export const Svg = styled.svg`
  position: absolute;
  height: ${(props) => props.width};
  width: ${(props) => props.width};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
