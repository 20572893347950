const overrideSettings = (
  embeddedService: any,
  fields: any[],
  settings: any,
) => {
  if (!embeddedService || !embeddedService.settings) return;

  fields.forEach((f) => {
    if (Object.prototype.hasOwnProperty.call(settings, f))
      // eslint-disable-next-line no-param-reassign
      embeddedService.settings[f] = settings[f];
  });
};

export default overrideSettings;
