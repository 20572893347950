import FooterOLX from 'components/organisms/Footer';

import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const Container = styled.div`
  margin-top: 48px;
  @media only screen and (min-width: 601px) {
    margin-top: 120px;
    align-items: center;
  }

  & > footer > div {
    max-width: 1224px;
  }
`;

const Footer = () =>
  !isMobile ? (
    <Container>
      <FooterOLX />
    </Container>
  ) : null;

export default Footer;
