import { useQuery } from '@apollo/client';
import { LinkList } from 'components/organisms';
import { GET_ARTICLES_BY_CATEGORY_ID } from 'graphql/queries';
import { Loading } from 'components/atoms';
import RecordFlattener from 'util/cmsRecordFlattener';

type Props = {
  categoryId: string;
};

const ArticlesList = ({ categoryId }: Props) => {
  const flattener = new RecordFlattener();
  const { loading, error, data } = useQuery(GET_ARTICLES_BY_CATEGORY_ID, {
    variables: {
      categoryId,
    },
  });

  const serializedArticles = flattener.flatten(data?.articles);

  if (loading) return <Loading />;
  if (error) return <div>Erro na leitura da página. Tente novamente.</div>;

  const list = serializedArticles.map((article: any) => ({
    ...article,
    title: article.name,
    url: `/s/article/${article.slug}`,
  }));

  return <LinkList list={list} />;
};

export default ArticlesList;
