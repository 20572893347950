import React from 'react';
import { clickLink } from 'tracking/lurker';
import isWithModifier from './isWithModifier';
import redirect from './redirect';

const handleClick = (
  event: React.MouseEvent<Element, MouseEvent>,
  objectDetail: string,
  objectName: string,
  objectData?: Parameters<typeof clickLink>[2],
) => {
  clickLink(objectDetail, objectName, objectData);
  const target = (event.target || event.currentTarget) as HTMLInputElement;
  const url = target.getAttribute('href') as string;
  event.preventDefault();

  if (!isWithModifier(event)) {
    setTimeout(() => {
      redirect(url);
    }, 300);
    return;
  }

  window.open(url);
};

export default handleClick;
