import { useState } from 'react';
import { dislikeFeedback } from 'tracking/lurker';
import InputRadio from 'components/atoms/radio-input';
import { Button, Text, Label, Form, TextArea, ContainerArea } from './styles';

type Props = {
  handleNextStep: Function;
  objectDetail: string;
  objectName: string;
  maxCharacters?: number;
  options?: string[];
};
const FormFeedback = ({
  handleNextStep,
  objectDetail,
  objectName,
  maxCharacters,
  options,
}: Props) => {
  const [selected, setSeleted] = useState('');
  const [messageValue, setMessageValue] = useState('');
  const showComment = !options || selected;

  const handleSubmit = () => {
    if (selected || messageValue) {
      dislikeFeedback(
        messageValue,
        selected.toString(),
        objectDetail,
        objectName,
      );
    }
    handleNextStep();
  };

  const onChange = (value: string) => {
    if (maxCharacters && value.length > maxCharacters) {
      setMessageValue(value.slice(0, maxCharacters));
    } else {
      setMessageValue(value);
    }
  };

  return (
    <div>
      <Text>Conta pra gente, por que essas informações não ajudaram?</Text>
      <Form onSubmit={handleSubmit}>
        {options?.map((option) => (
          <Label key={option}>
            <InputRadio
              selected={selected}
              value={option}
              onChange={setSeleted}
              label={option}
            />
          </Label>
        ))}

        {showComment && (
          <>
            <ContainerArea>
              <TextArea
                multiline
                placeholder="Seu comentário vai ajudar a gente a melhorar nossos conteúdos e serviços. (opcional)"
                value={messageValue}
                onChange={(ev: any) => onChange(ev.target.value)}
              />
              {maxCharacters && (
                <span>
                  {messageValue.length}/{maxCharacters}
                </span>
              )}
            </ContainerArea>
            <Button onClick={handleSubmit}>Enviar</Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default FormFeedback;
