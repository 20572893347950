import styled from 'styled-components';
import { SimpleLink as DefaultSimpleLink } from 'components/atoms';

const SimpleLink = styled(DefaultSimpleLink)`
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
`;

const StyledWrapDiv = styled.div`
  color: #6e0ad6;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
`;

type Props = {
  links: {
    href: string;
    title: string;
  }[];
};

const buildBreadcrumbLink = (
  item: { href: string; title: string },
  index: number,
  arr: any[],
) => (
  <>
    <SimpleLink key={`${item.title}-${item.href}`} href={item.href}>
      {item.title}
    </SimpleLink>
    {index < arr.length - 1 && ' / '}
  </>
);

const JsonLD = ({ links }: Props) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: links.map((link, index) => ({
          '@type': 'ListItem',
          position: index + 1,
          name: link.title,
          item: `https://ajuda.olx.com.br${link.href}`,
        })),
      }),
    }}
  />
);

const Breadcrumb = ({ links }: Props) => (
  <StyledWrapDiv>
    {links.map(buildBreadcrumbLink)}
    <JsonLD links={links} />
  </StyledWrapDiv>
);

export default Breadcrumb;
