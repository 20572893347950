import { ChatType } from 'components/organisms/list/types';
import { chatPayOptions, chatPriOptions } from './chatOptions';

export const getChatOptions = (chatType: ChatType) => {
  switch (chatType) {
    case 'OLX_PAY':
      return chatPayOptions;
    case 'OLX_BR':
    default:
      return chatPriOptions;
  }
};
