import { gql } from '@apollo/client';

export const SEARCH_PUBLISHED_ARTICLES = gql`
  query searchArticle($query: String!) {
    searchArticle(query: $query) {
      data {
        id
        attributes {
          slug
          name
        }
      }
    }
  }
`;
