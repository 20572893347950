import { ReactNode, MouseEventHandler } from 'react';
import DialogMUI from '@material-ui/core/Dialog';
import { Button as OLXButton } from '@olxbr/ds-web';
import { System } from '@olxbr/olx-icons';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentTextMUI from '@material-ui/core/DialogContentText';
import DialogTitleMUI from '@material-ui/core/DialogTitle';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

type Props = {
  children: ReactNode;
  visible: boolean;
  title: string;
  nextHandler: MouseEventHandler;
  cancelHandler: MouseEventHandler;
  nextButtonMessage?: string;
  closeButtonMessage?: string;
};

const DialogTitle = styled(DialogTitleMUI)`
  font-size: 20px;
  line-height: 20px;
  margin-top: 30px;
  color: #4a4a4a;
  font-weight: 600;
  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`;

const DialogContentText = styled(DialogContentTextMUI)``;

const Dialog = styled(DialogMUI)`
  * {
    font-family: Nunito sans;
  }
  .MuiDialog-paper {
    width: 600px;
    min-height: 146px;
    padding: 32px;
    padding-top: 25px;
    @media only screen and (max-width: 600px) {
      padding: 32px 24px;
      min-height: 0;
      width: 100%;
      margin: 0;
      min-height: 30vh;
      margin-bottom: 0px;
      margin-top: auto;
    }
  }
  .MuiDialogTitle-root,
  .MuiTypography-h6 {
    padding: 0;
    @media only screen and (max-width: 600px) {
      letter-spacing: 0;
      line-height: 28px;
    }
  }
  .MuiDialogContent-root {
    padding: 0;
    margin-bottom: 16px;
    margin-top: 6px;
    @media only screen and (max-width: 600px) {
      margin-top: 8px;
      margin-bottom: 20px;
    }
    * {
      color: #4a4a4a;
    }
  }
  .MuiDialogActions-root {
    place-self: flex-end;
    padding: 0;
    grid-auto-flow: column;
    grid-template-columns: auto 24px auto;
    display: grid;
    @media only screen and (max-width: 320) {
      grid-template-columns: 46% 8% 46%;
      place-self: center;
    }
  }
`;

const CloseButton = styled.div`
  width: 100%;
  text-align: right;
  color: #999999;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  position: relative;
  @media only screen and (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

const ButtonContainner = styled.div``;

const Button = styled(OLXButton)`
  float: right;
`;

const NoticeModal = (props: Props) => {
  const {
    children,
    visible,
    title,
    nextHandler,
    nextButtonMessage,
    closeButtonMessage,
    cancelHandler,
  } = props;

  return (
    <>
      <Dialog
        open={visible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        disableBackdropClick
      >
        <CloseButton>
          <System.X onClick={nextHandler} />
        </CloseButton>

        <ContentWrapper>
          {title ? (
            <DialogTitle id="alert-dialog-title">
              <strong>{title}</strong>
            </DialogTitle>
          ) : (
            ''
          )}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {children}
            </DialogContentText>
          </DialogContent>
          <ButtonContainner>
            <Button onClick={nextHandler} fullWidth={isMobile}>
              {nextButtonMessage}
            </Button>
            <Button
              variant="link-button"
              onClick={cancelHandler}
              marginRight="--spacing-inline-xxxs"
              fullWidth={isMobile}
              marginTop={isMobile ? '--spacing-inline-xxxs' : ''}
            >
              {closeButtonMessage}
            </Button>
          </ButtonContainner>
        </ContentWrapper>
      </Dialog>
    </>
  );
};

NoticeModal.defaultProps = {
  closeButtonMessage: 'Cancelar',
  nextButtonMessage: 'Ok',
};

export default NoticeModal;
