const nextRoutes = require('next-routes');

const routes = nextRoutes();

/* eslint jest/require-hook: "warn" */
routes.add('contact-us', '/fale-conosco');
routes.add('incident-info', '/incidente/:id');
routes.add('false-call-ticket', '/chamado/:routes');
routes.add('unpublished-ad', '/anuncios-recusados');
routes.add('my-tickets', '/meus-atendimentos');
routes.add('blocked-account', '/conta-bloqueada');
routes.add('help-form', '/fale-conosco-formulario');
routes.add(
  'fast-resolution/my-purchases',
  '/resolva-mais-rapido/minhas-compras/:id',
);
routes.add('fast-resolution', '/resolva-mais-rapido/:id');
routes.add('contact-options', '/opcoes-de-contato');
routes.add('contact-options-bot', '/opcoes-de-contatos');
routes.add('contact-options-intercom', '/opcoes-de-contato-icom');
routes.add('contact-form', '/formulario-de-contato');
routes.add('dispute-form', '/formulario-de-disputa/:id');
routes.add('talk-direct-to-us', '/fale-direto-conosco');

module.exports = routes;
