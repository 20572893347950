import { ReactNode, useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { MarkdownRender } from 'components/organisms';
import { TagAMarkdown } from 'components/atoms';
import { BannerContext } from 'components/context';
import { clickLink, visibleTrack } from 'tracking/lurker';

const Container = styled.div`
  margin: -8px;
  margin-bottom: 0;
  background-color: #f0f0f0;
  padding: 16px;
  padding-right: 40px;
  justify-content: center;
  align-items: center;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};

  @media (min-width: 768px) {
    padding: 16px 40px;
  }

  p {
    margin: 10px 0 0 !important;
  }
`;

type BannerProps = {
  href: string;
  children: ReactNode;
  bannerContent: string;
};

const BannerLink = ({ href, children, bannerContent }: BannerProps) => (
  <TagAMarkdown
    href={href}
    onClick={() => {
      clickLink('click_banner', 'central_home', { href, bannerContent });
    }}
  >
    {children}
  </TagAMarkdown>
);

const Image = styled.img`
  position: absolute;
  right: 16px;
  cursor: pointer;
`;

const Banner = () => {
  const { banners } = useContext(BannerContext);
  const [showContainer, setshowContainer] = useState(true);

  const closeBanner = () => {
    clickLink('fechar_banner', 'central_home', {
      content: banner?.content ?? '',
    });
    setshowContainer(false);
  };

  const banner = banners?.find((b) => !b.incident);

  useEffect(() => {
    if (banner && showContainer) {
      visibleTrack('visible_banner', 'central_home', {
        content: banner.content,
      });
    }
  }, [banner, showContainer]);

  if (!banner) {
    return null;
  }

  return (
    <Container hidden={!showContainer}>
      <MarkdownRender
        options={{
          overrides: {
            a: {
              component: BannerLink,
              props: { bannerContent: banner.content },
            },
          },
        }}
      >
        {banner?.content ?? ''}
      </MarkdownRender>

      <Image
        src="/help_center_images/close.svg"
        alt="close"
        onClick={closeBanner}
      />
    </Container>
  );
};

export default Banner;
