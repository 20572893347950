import { Slider } from 'components/molecules';

type Props = {
  children: any[];
};

const SliderRenderer = ({ children }: Props) => {
  const images = children
    .filter((image) => Boolean(image.props))
    .map((image) => ({
      src: image.props.src || '',
      alt: image.props.alt || '',
    }));

  return <Slider images={images} />;
};

export default SliderRenderer;
