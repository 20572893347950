const mediaQuery = {
  extraLarge: (styles) => `@media (max-width: 1200px) { ${styles} }`,
  large: (styles) => `@media (max-width: 992px) { ${styles} }`,
  medium: (styles) => `@media (min-width: 768px) { ${styles} }`,
  small: (styles) => `@media (min-width: 576px) { ${styles} }`,
  fontMax: (styles) => `@media (min-width: 1200px) { ${styles} }`,
  fontMin: (styles) => `@media (min-width: 576px) { ${styles} }`,
};

export default mediaQuery;
