import getLoginUrl from 'util/getLoginUrl';
import getPictureFromUser from 'util/getPictureFromUser';
import isWithModifier from 'util/isWithModifier';
import redirect from 'util/redirect';
import { useLoggedUser } from 'hooks';
import { clickLink } from 'tracking/lurker';
import { Avatar, Text } from '@olxbr/ds-web';
import { Wrapper } from './styles';

const UserMenuDesktop = () => {
  const { user } = useLoggedUser();
  const label = user ? user.firstName?.split(' ')[0] : 'Entrar';

  const handleClick = async (ev: any) => {
    ev.preventDefault();
    clickLink('entrar', 'central_home');

    const isEventWithModifier = isWithModifier(ev);

    const url = await getLoginUrl();
    if (url) {
      if (!isEventWithModifier) {
        setTimeout(() => {
          redirect(url);
        }, 100);
        return;
      }

      window.open(url);
    }
  };

  const action = user ? undefined : handleClick;

  return (
    <Wrapper onClick={action}>
      {user && (
        <Avatar
          size="32px"
          src={getPictureFromUser(user) ?? ''}
          alt="Uma imagem de um avatar representando perfil."
        />
      )}

      <Text.BodySmall weight="semibold">{label}</Text.BodySmall>
    </Wrapper>
  );
};

export default UserMenuDesktop;
