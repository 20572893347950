import styled from 'styled-components';

const TagULMarkdown = styled.ul`
  list-style: none;
  li {
    &:before {
      content: '• ';
      color: #d2d2d2;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      line-height: 1.5;
    }
    font-family: 'Nunito Sans', sans-serif;
    margin-bottom: 8px;
    line-height: 24px;
  }
`;

export default TagULMarkdown;
