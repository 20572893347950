import styled from 'styled-components';

type Props = {
  noMargin?: boolean;
};

const Divider = styled.div`
  border-bottom: 1px solid #d2d2d2;
  margin: 32px 0;

  ${({ noMargin }: Props) => noMargin && 'margin: 0;'}
`;

Divider.defaultProps = {
  noMargin: false,
};

export default Divider;
