import styled from 'styled-components';
import { Input, Button as OlxButton } from '@olxbr/design-system';

export const Button = styled(OlxButton)`
  font-weight: 600;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 24px;
  background-color: #f28000;
  min-width: unset;
  height: unset;
`;

export const Text = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin-top: 0.5rem;
  color: #4a4a4a;
`;

export const Label = styled.div`
  display: block;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin: 14px 0;
  color: #4a4a4a;

  label:last-child span {
    margin-left: 18px;
  }
`;

export const Form = styled.form`
  margin-top: 34px;
  margin-bottom: -10px;
  @media only screen and (max-width: 600px) {
    margin-bottom: 0px;
  }
`;

export const ContainerArea = styled.div`
  margin: 24px 0;

  span {
    font-family: 'Nunito Sans', sans-serif;
    color: #3c4453;
    margin: 0 5px 0 5px;
    float: right;
  }
`;

export const TextArea = styled(Input)`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  height: 85px;
  resize: none;
  :focus {
    box-shadow: 0px 0px 0px 4px #f7f1fd;
  }
`;
